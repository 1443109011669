import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import ProductForm from "../forms/ProductForm";
import ProductImages from "../productComponents/ProductImages";

function ProductEdit({
  isOpen,
  handleClose,
  productId,
  data,
  WhatDoYouWant = "edit",
  pageRefresh,
}) {
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth={"lg"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {`Edit Product`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="block md:flex gap-3">
            <div className="w-full md:w-[60%]">
              <ProductForm
                data={data}
                productId={productId}
                WhatDoYouWant={WhatDoYouWant}
                handleClose={handleClose}
                pageRefresh={() => {
                  pageRefresh();
                }}
              />
            </div>
            <div className="w-full md:w-[40%]">
              <ProductImages productId={productId} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ProductEdit;
