import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import SignupPage from "./pages/auth/Signup";
import LoginPage from "./pages/auth/Login";
import ForgotPasswordPage from "./pages/auth/ForgotPassword";
import NotFoundPage from "./pages/NotFound";
import HomePage from "./pages/home";
import Footer from "./components/app/Footer";
import Header from "./components/app/Header";
import DashboardPage from "./pages/dashboard";
import DistributorsPage from "./pages/distributors/index.jsx";
import UsersPage from "./pages/users";
import DistributorsListPage from "./pages/distributors/list/index.jsx";
import MyIncomePage from "./pages/my_income/index.jsx";
import MyAccountPage from "./pages/my_account/index.jsx";
import AboutPage from "./pages/about";
import ContactUsPage from "./pages/contact_us/index.jsx";
import ResetPasswordPage from "./pages/auth/ResetPassword.jsx";
import { UserDetailsPage } from "./pages/users/UserDetailsPage.jsx";
import ProductPage from "../src/pages/products/index.js";
import ProductDetailPage from "../src/pages/products/ProductDetail.jsx";
import ToastContainer from "../src/components/ToastContainer.jsx";
import * as api from "./utils/apiClient.js";
import { getCookie, setCookie } from "./utils/cookies";
import CreateProductPage from "./pages/products/admin_pages/CreateProduct.jsx";
import Terms from "./pages/terms_and_conditions/page.jsx";
import PrivacyPolicy from "./pages/privacy_policy/page.jsx";
import ProductImagesPage from "./pages/products/admin_pages/ProductImages.jsx";
import AllProduct from "./pages/products/admin_pages/AllProduct.jsx";
import ProductDetail from "./pages/products/admin_pages/ProductDetail.jsx";
import DistributorsPrivateRoutes from "./components/privateRoutes/DistributorsPrivateRoutes.jsx";
import AdminPrivateRoutes from "./components/privateRoutes/AdminPrivateRoutes.jsx";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#4338CA",
        contrastText: "#FFFFFF",
      },
      error: {
        main: "#E01919",
        contrastText: "#FFFFFF",
      },
      neutral: {
        1: "#FEFEFE",
        2: "#F3F5F7",
        3: "#E8ECEF",
        4: "#6C7275",
        5: "#343839",
        6: "#232627",
        7: "#141718",
      },
      background: {
        default: "#FEFEFE",
      },
      text: {
        primary: "#141718",
        secondary: "#6C7275",
      },
    },
  });
  const [isLogin, setIsLogin] = useState(() => {
    const access_token = getCookie("access_token");
    if (access_token == null || access_token.length <= 0) {
      return false;
    }
    api.setAuthHeader("access_token");
    return true;
  });
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();

  const renewToken = async () => {
    try {
      const { data } = await api.renewAccessToken();
      const accessTokenExpiresAt = Date.parse(data.access_token_expires_at);
      const currentMilies = Date.now();
      const access_token = data.access_token;
      setCookie(
        "access_token",
        access_token,
        parseInt((accessTokenExpiresAt - currentMilies) / 1000)
      );
      api.setAuthHeader("access_token");
      api.setFormDataAuthHeader("access_token");
      setIsLogin(true);
    } catch (err) {
      console.log(err);
      setIsLogin(false);
    }
  };

  const fetchMyProfile = async () => {
    try {
      const { data } = await api.getMyProfile();
      setProfile(data?.user);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkLogin = () => {
      const accessToken = getCookie("access_token");
      const refreshToken = getCookie("refresh_token");

      if (accessToken || accessToken !== null) {
        return;
      }

      if (refreshToken && refreshToken !== null) {
        api.setAuthHeader("refresh_token");
        renewToken();
        return;
      } else {
        if (isLogin) {
          navigate(0);
        }
      }
    };

    const loginInterval = setInterval(checkLogin, 1000);
    if (isLogin) {
      fetchMyProfile();
    }
    return () => clearInterval(loginInterval);
  }, [isLogin, navigate]);

  return (
    <AuthContext.Provider value={{ isLogin, setIsLogin, profile, setProfile }}>
      <ThemeProvider theme={theme}>
        <Header />
        <Routes>
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot_password" element={<ForgotPasswordPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/products/:id" element={<ProductDetailPage />} />
          <Route
            path="/reset_password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/contact_us" element={<ContactUsPage />} />
          {/* All Protected Page Goes Here */}
          <Route element={<DistributorsPrivateRoutes />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/distributors" element={<DistributorsPage />} />
            <Route
              path="/distributors/list"
              element={<DistributorsListPage />}
            />
            <Route path="/users" element={<UsersPage />} />
            <Route element={<AdminPrivateRoutes />}>
              <Route path="/products/create" element={<CreateProductPage />} />
              <Route
                path="/products/images/:id"
                element={<ProductImagesPage />}
              />
              <Route path="/products/list" element={<AllProduct />} />
              <Route path="/product/:id" element={<ProductDetail />} />
            </Route>
            <Route path="/users/:id" element={<UserDetailsPage />} />
            <Route path="/income" element={<MyIncomePage />} />
            <Route path="/account" element={<MyAccountPage />} />
          </Route>
          <Route path="/terms_and_conditions" element={<Terms />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
