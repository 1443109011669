import * as React from "react";
import Button from "@mui/material/Button";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function ShortDetailsAboutCorporate() {
  return (
    <div className="flex flex-col md:flex-row items-center gap-10 px-5 md:px-0 py-5">
      <div className="w-full md:w-[60%] flex flex-col gap-3 text-start">
        <h3 className="text-3xl font-medium">
          Join MS International and Transform Your Life!
        </h3>
        <p>
          At MS International, we believe in empowering individuals to achieve
          their dreams and create a life of abundance. Our unique network
          marketing opportunity opens doors to:
        </p>
        <ul className="flex flex-col gap-1.5 list-inside list-disc">
          <li>
            Health: Access to quality wellness products for you and your loved
            ones.
          </li>
          <li>
            Happiness: Build meaningful relationships and find joy in helping
            others succeed.
          </li>
          <li>
            Family Time: Flexible work allows you to prioritize precious moments
            with your family.
          </li>
          <li>
            Luxury Vacations: Earn rewards that let you explore the world in
            style.
          </li>
          <li>
            Education for Your Children: Secure a bright future for the next
            generation.
          </li>
          <li>
            Extra Income: Supplement your earnings and achieve financial
            freedom.
          </li>
          <li>
            Dream Luxury Car: Turn your automotive fantasies into reality.
          </li>
          <li>Dream House: Build the home you've always envisioned.</li>
          <li>
            Fabulous Retirement: Ensure comfort and security in your golden
            years.
          </li>
        </ul>
        <Link to="/about">
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<FaArrowRight />}
          >
            Read More
          </Button>
        </Link>
      </div>
      <div className="w-full md:w-[40%] order-first md:order-last">
        <img
          src={require("../../assets/three.png")}
          className=""
          alt="read_more"
        />
      </div>
    </div>
  );
}

export default ShortDetailsAboutCorporate;
