/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ProtectedLayout from "../../components/layouts/ProtectedLayout";
import * as api from "../../utils/apiClient";
import Loading from "../../components/Loading";
import SearchSection from "../../components/sections/SearchSection";
import { AuthContext } from "../../context/AuthContext";
import UserTreeViewCard from "../../components/card/UserTreeViewCard";

function DistributorsPage() {
  const { profile } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_id: "",
  });
  const [data, setData] = useState({});

  const getAllDistributorTree = async (user_id) => {
    setIsLoading(true);
    try {
      const { data } = await api.getMlmTree(
        50,
        user_id
          ? user_id
          : formData?.user_id.split("MS-")[1]
          ? formData?.user_id.split("MS-")[1]
          : formData?.user_id
          ? formData?.user_id
          : profile?.id
      );
      if (data) {
        setData(data.users[0]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllDistributorTree();
  }, []);

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      <div>
        <div className="w-full flex justify-center relative">
          <SearchSection
            formData={formData}
            setFormData={setFormData}
            searchDistributor={() => getAllDistributorTree()}
          />
        </div>
        <div>
          <UserTreeViewCard
            data={data}
            getUsersTree={(user_id) => {
              getAllDistributorTree(user_id);
            }}
          />
        </div>
      </div>
    </ProtectedLayout>
  );
}

export default DistributorsPage;
