import React from "react";
import TopHeroSection from "../../components/sections/TopHeroSection";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div>
      <TopHeroSection title="Privacy Policy" />
      <div className="max-w-[1440px] container mx-auto px-5 sm:px-0 relative bg-neutral-2">
        <div className="max-w-5xl mx-auto border p-5 sm:p-10 bg-white rounded -translate-y-28">
          <p>
            At <b>MS International</b>, we are committed to protecting your
            privacy, we prioritize the privacy and security of the personal data
            provided by visitors to our website and members of our network. We
            are committed to maintaining the highest standards of
            confidentiality and secure transactions. This privacy policy
            outlines how we collect, use, and safeguard your information, and
            the choices you can make about how your information is used.
          </p>
          <br />
          <div>
            <ol className="list-decimal px-5">
              <li>
                <h5 className="font-semibold text-lg">
                  Information Collection:
                </h5>
                <ul>
                  <li>
                    <ul className="list-inside list-disc flex flex-col gap-2">
                      <li>
                        We collect personal information such as your name, email
                        address, phone number, and other relevant data when you
                        register an account with us or place an order. This
                        information is required for providing our services, such
                        as product orders, managing your membership, and
                        accessing exclusive features on our websites{` (`}
                        including
                        {""}
                        <Link
                          target="_blank"
                          to={"https://www.msinternational.org.in/"}
                          className="text-secondary px-1"
                        >
                          www.msinternational.org.in
                        </Link>
                        , its related websites, and mobile apps{`).`}
                      </li>
                      <li>
                        Additionally, when you visit our website, we may collect
                        non-personal information like your IP address, browser
                        type, operating system, and the pages you visit. This
                        data helps us enhance user experience and is not linked
                        back to your personal identity.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Use and Sharing of Information:
                </h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    We strictly ensure that your personal information remains
                    confidential and secure. We use your information for
                    purposes such as processing orders, improving our services,
                    sending promotional offers, and delivering updates.
                  </li>
                  <li>
                    While we do not share your personal data with third parties
                    for marketing purposes without consent, we may share
                    information with authorized third-party service providers,
                    such as delivery partners, email service providers, and
                    customer support, to fulfill your orders and enhance your
                    experience. These providers are required to handle your data
                    securely and in accordance with our privacy policies.
                  </li>
                  <li>
                    In certain cases, we may share your information as required
                    by law or in the event of a company merger, sale, or
                    transfer of assets.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Use of Demographic and Profile Data:
                </h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    We use demographic data to analyze and improve our services.
                    Your data helps us understand customer preferences and
                    customize your experience on our site. We may use your
                    information to inform you of products, services, and
                    promotions that may interest you. You can opt-out of
                    marketing communications at any time by following the
                    <b> unsubscribe </b> link provided in such emails.
                  </li>
                  <li>
                    {`We may also collect IP addresses to diagnose server issues
                    and administer our website effectively. Occasionally, we may
                    ask for optional survey data to help us better understand
                    our users’ preferences and improve our services.`}
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Cookies:</h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    Our website uses cookies to improve your browsing
                    experience. Cookies help us track your session, save your
                    preferences, and tailor content to your interests. You can
                    choose to accept or decline cookies, but please note that
                    some features of the website may not function properly if
                    cookies are disabled.
                  </li>
                  <li>
                    We do not share any personally identifiable information
                    collected via cookies with third parties.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Links to External Sites:
                </h5>
                <ul>
                  <li>
                    {`Our website may contain links to third-party websites (such
                    as social media platforms). We are not responsible for the
                    privacy practices of those sites and encourage you to read
                    their privacy policies. We provide these links in good
                    faith, but we do not have control over the content or
                    privacy standards of those external sites.`}
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Security Measures:</h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    At <b>MS International</b>, we employ industry-standard
                    security measures to protect your personal information from
                    unauthorized access, misuse, or disclosure. We use
                    encryption and secure servers to safeguard sensitive data,
                    ensuring that your information is well-protected throughout
                    its lifecycle with us.
                  </li>
                  <li>
                    Rest assured, we regularly review and update our security
                    protocols to keep your data safe from evolving threats.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Policy Updates:</h5>
                <ul>
                  <li>
                    We reserve the right to update or amend this privacy policy
                    as needed. Any changes will be posted on our website, and we
                    encourage you to review this policy periodically. Your
                    continued use of our website following any changes signifies
                    your acceptance of the updated policy.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Opt-Out Options:</h5>
                <ul>
                  <li>
                    You have the right to opt out of receiving non-essential
                    communications from us at any time. To unsubscribe from
                    marketing and promotional emails, click the unsubscribe link
                    provided in the email. This will not affect any
                    transactional or essential communications related to your
                    membership or orders.
                  </li>
                </ul>
              </li>
            </ol>
            <p className="pt-1.5 italic">Effective Date: 01-09-2024</p>
          </div>
          <br />
          <div>
            <span className="font-semibold text-lg">Data Privacy:&nbsp;</span>
            This privacy policy is designed specifically for{" "}
            <b>MS International</b> to ensure transparency in how you handle
            customer data while complying with best practices in network
            marketing.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
