import React, { useContext, useEffect } from "react";
import LoginForm from "../../components/forms/LoginForm";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const LoginPage = () => {
  const { isLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [isLogin, navigate]);

  return (
    <>
      <div>
        <LoginForm />
      </div>
    </>
  );
};

export default LoginPage;
