import React, { useEffect, useState } from "react";
import { Button, Input, TextField } from "@mui/material";
import Loading from "../Loading";
import { toast } from "react-toastify";
import * as api from "../../utils/apiClient";

function ProductForm({
  productId,
  data,
  WhatDoYouWant,
  handleClose,
  pageRefresh,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [productFormData, setProductFormData] = useState({
    name: "",
    description: "",
    category: "",
    discount: "",
    margin: "",
    price: "",
    thumbnail: {},
  });

  // edit data will be here (set to state)
  useEffect(() => {
    if (data) {
      setProductFormData({
        name: data.name,
        description: data.description,
        category: data.category,
        discount: data.discount + "",
        margin: data.margin + "",
        price: data.price + "",
        // thumbnail: data.thumbnail,
      });
    }
  }, [data]);

  const handleChange = (event) => {
    if (event.target.name === "thumbnail") {
      setProductFormData({
        ...productFormData,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setProductFormData({
        ...productFormData,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Creating a Product
  const createProduct = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData();
    data.append("name", productFormData.name);
    data.append("description", productFormData.description);
    data.append("category", productFormData.category);
    data.append("discount", productFormData.discount);
    data.append("margin", productFormData.margin);
    data.append("price", productFormData.price);
    data.append("thumbnail", productFormData.thumbnail);
    try {
      const res = await api.createProduct(data);
      if (res.data.product) {
        productId(res.data.product.product_id);
      }
      setProductFormData({
        name: "",
        description: "",
        category: "",
        discount: "",
        margin: "",
        price: "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  // update a Product
  const updateProduct = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData();
    data.append("name", productFormData.name);
    data.append("description", productFormData.description);
    data.append("category", productFormData.category);
    data.append("discount", productFormData.discount);
    data.append("margin", productFormData.margin);
    data.append("price", productFormData.price);
    data.append("thumbnail", productFormData.thumbnail);
    try {
      const res = await api.updateProduct(productId, data);
      if (res) {
        pageRefresh();
      }
      setProductFormData({
        name: "",
        description: "",
        category: "",
        discount: "",
        margin: "",
        price: "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <h1 className="font-medium pb-2">Create Product</h1>
      <form action="#" className="flex flex-col gap-3 w-full">
        <div>
          <TextField
            id="name"
            label="Name"
            value={productFormData.name}
            variant="outlined"
            fullWidth={true}
            size="small"
            color="info"
            required
            name="name"
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            id="thumbnail"
            name="thumbnail"
            type="file"
            disableUnderline={true}
            fullWidth={true}
            className="w-full border border-mediumgray border-opacity-50 hover:border-black rounded-md outline-none cursor-pointer p-1"
            onChange={handleChange}
          ></Input>
        </div>
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2">
          <div>
            <TextField
              id="price"
              label="Distributor Price"
              value={productFormData.price}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="price"
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              id="category"
              label="Category"
              value={productFormData.category}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="category"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2">
          <div>
            <TextField
              id="discount"
              label="MRP"
              value={productFormData.discount}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="discount"
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              id="margin"
              label="Business value (Percentage)"
              value={productFormData.margin}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              name="margin"
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <TextField
            id="description"
            label="Description"
            value={productFormData.description}
            variant="outlined"
            fullWidth={true}
            size="small"
            color="info"
            multiline
            rows={4}
            required
            name="description"
            onChange={handleChange}
          />
        </div>
        <div className="text-end">
          <Button
            disabled={
              !productFormData.name.length > 0 ||
              !productFormData.category.length > 0 ||
              !productFormData.price.length > 0 ||
              !productFormData.discount.length > 0 ||
              !productFormData.margin.length > 0 ||
              !productFormData.description.length > 0
            }
            variant="contained"
            color="secondary"
            disableElevation
            onClick={(event) => {
              if (WhatDoYouWant === "edit") {
                updateProduct(event);
              } else {
                createProduct(event);
              }
              if (handleClose) {
                handleClose();
              }
            }}
          >
            {WhatDoYouWant === "edit" ? "Save" : "Submit"}
          </Button>
        </div>
      </form>
    </>
  );
}

export default ProductForm;
