import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import * as api from "../../../utils/apiClient";
import Loading from "../../../components/Loading";
import ProtectedLayout from "../../../components/layouts/ProtectedLayout";
import { formatDateTimeWithAMAndPM } from "../../../utils/DateCalculate";

function AllProduct() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllProduct = async () => {
      setLoading(true);
      try {
        const { data } = await api.getAllProducts();
        if (data) {
          const array = [];
          data?.products?.forEach((element) => {
            const obj = { ...element };
            obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
            obj.thumbnail = (
              <Avatar
                src={element.thumbnail}
                alt="logo"
                sx={{ width: 50, height: 50 }}
              />
            );
            obj.price = "₹" + element.price;
            obj.margin = "₹" + element.margin;
            obj.discount = element.discount + "%";
            array.push(obj);
          });
          setData(array);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getAllProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 50,
      },
      {
        accessorKey: "thumbnail",
        header: "Thumbnail",
        size: 50,
        filter: false,
      },
      {
        accessorKey: "name",
        header: "Product Name",
        size: 150,
      },
      {
        accessorKey: "price",
        header: "Price",
        size: 50,
      },
      {
        accessorKey: "margin",
        header: "Margin",
        size: 50,
      },
      {
        accessorKey: "discount",
        header: "Discount",
        size: 50,
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/product/${row.original.product_id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <ProtectedLayout>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <div className="flex justify-between items-center pb-1.5">
          <h1 className="font-medium text-2xl">All Products</h1>
          <Button variant="text" onClick={() => navigate("/products/create")}>
            Create Product
          </Button>
        </div>
        <MaterialReactTable table={table} />
      </div>
    </ProtectedLayout>
  );
}

export default AllProduct;
