import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import * as api from "../../../utils/apiClient";
import { useNavigate, useParams } from "react-router-dom";
import ProtectedLayout from "../../../components/layouts/ProtectedLayout";
import { MdDelete, MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Confirmation from "../../../components/pop/Confirmation";
import ProductEdit from "../../../components/pop/ProductEdit";
import { calculateDiscountPrice } from "../../../utils/PriceCalculate";

const ProductDetail = () => {
  // TODO: Make related product by categories...
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState();
  const [productEditIsOpen, setProductEditIsOpen] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);

  useEffect(() => {
    const getOneProduct = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getPublicProductById(id);
        if (data) {
          setData(data.product);
          setCurrentImage(data.product.thumbnail);
        }
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.data?.message) {
          // toast.error(error.response.data.message); // productData No product found with that ID
          navigate("/products/list");
        }
        setIsLoading(false);
        console.log(error);
      }
    };
    getOneProduct();
  }, [id, pageRefresh, navigate]);

  const deleteProduct = async () => {
    try {
      const { data } = await api.deleteProduct(id);
      if (data) {
        navigate("/products/list");
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      {productEditIsOpen && (
        <ProductEdit
          isOpen={productEditIsOpen}
          handleClose={() => setProductEditIsOpen(false)}
          productId={id}
          data={data}
          WhatDoYouWant="edit"
          pageRefresh={() => {
            setPageRefresh(!pageRefresh);
          }}
        />
      )}
      {deleteConfirmation && (
        <Confirmation
          handleClose={() => setDeleteConfirmation(false)}
          isOpen={deleteConfirmation}
          confirmToCallFunction={deleteProduct}
          massage={`This action cannot be undone. This will permanently delete the product
            "${data?.name}" and remove it from our servers.`}
        />
      )}
      <div className="max-w-[1440px] container px-5 sm:px-0 mx-auto py-5">
        {/************************ Product Details*********************************/}
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 relative">
            <div className="flex items-center gap-1.5 absolute top-3 right-3">
              <MdEdit
                className="size-8 text-neutral-4 hover:text-secondary cursor-pointer duration-300"
                onClick={() => {
                  // alert("Under Development");
                  setProductEditIsOpen(true);
                }}
              />
              <MdDelete
                className="size-8 text-neutral-4 hover:text-error cursor-pointer duration-300"
                // onClick={() => deleteProduct()}
                onClick={() => setDeleteConfirmation(true)}
              />
            </div>
            <div className="w-full sm:w-1/2 mx-auto">
              <div className="relative flex flex-col gap-3">
                {/* Main Image */}
                <img
                  src={currentImage}
                  alt={data?.name}
                  className="w-full h-80 object-cover rounded"
                />
                {/* Thumbnails */}
                <div className="flex flex-row gap-1 sm:gap-3 items-center overflow-x-auto">
                  {data?.image_urls?.map((pic, index) => (
                    <img
                      key={index}
                      src={pic}
                      alt={`Thumbnail ${index}`}
                      className={`w-10 sm:w-16 h-10 sm:h-16 rounded-full sm:rounded object-cover cursor-pointer transition-all duration-300 border-2 m-1 ${
                        currentImage === pic
                          ? "border-secondary"
                          : "hover:border-neutral-5"
                      }`}
                      onMouseEnter={() => setCurrentImage(pic)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <h1 className="text-3xl font-semibold pt-0 sm:pt-2">
                {data?.name}
              </h1>
              <div className="flex gap-2 items-center">
                <p className="text-sm font-semibold line-through text-neutral-4">
                  {`₹${data?.price}`}
                </p>
                <p className="text-2xl font-semibold text-secondary">
                  {`₹${calculateDiscountPrice(data?.price, data?.discount)}`}
                </p>
              </div>
              <div className="">
                <p className="text-lg flex items-center">
                  Availability:
                  <span className="font-bold pl-1.5 text-success">
                    In Stock
                  </span>
                </p>
              </div>
              <div className="mb-6">
                <h2 className="text-xl font-semibold">Product Description</h2>
                <p className="text-gray-700 leading-relaxed">
                  {data?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

export default ProductDetail;
