import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import React, { useContext, useState } from "react";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import * as api from "../../utils/apiClient";
import Loading from "../Loading";

const PhotoUploadSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PhotoPreview = styled("img")({
  width: "120px",
  height: "120px",
  objectFit: "cover",
  borderRadius: "8px",
  marginBottom: "16px",
});

const Placeholder = styled("div")({
  width: "120px",
  height: "120px",
  border: "2px dashed #ddd",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f0f0f0",
  color: "#aaa",
  fontSize: "16px",
  marginBottom: "16px",
});

const StyledForm = styled(Card)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[0],
}));

function ProfilePhotoUpdateFormPopup({ isOpen, handleClose }) {
  const [photoPreview, setPhotoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useContext(AuthContext);

  const handlePhotoChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("photo", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleForm = async (photo) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("profile_Photo_url", photo);
    try {
      const { data } = await api.personalDetailUpdate(profile.id, formData);
      if (data) {
        handleClose();
        toast.success(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (values.photo) {
      handleForm(values.photo);
    } else {
      toast.error("Please select a photo");
    }
    setSubmitting(false);
  };
  return (
    <>
      {isLoading && <Loading />}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Profile Photo
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Formik
            initialValues={{
              photo: null,
            }}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <StyledForm>
                <CardContent>
                  <Form className="flex flex-col gap-3 relative">
                    <div className="flex flex-col sm:flex-row gap-3">
                      {/* Photo */}
                      <div className="w-full">
                        <PhotoUploadSection>
                          {photoPreview ? (
                            <PhotoPreview src={photoPreview} alt="Preview" />
                          ) : (
                            <Placeholder>No Photo</Placeholder>
                          )}
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="photo-upload"
                            type="file"
                            name="photo"
                            onChange={(event) =>
                              handlePhotoChange(event, setFieldValue)
                            }
                          />
                          <label htmlFor="photo-upload">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              size="small"
                            >
                              Upload Photo
                            </Button>
                          </label>
                        </PhotoUploadSection>
                      </div>
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disableElevation
                    >
                      Submit
                    </Button>
                  </Form>
                </CardContent>
              </StyledForm>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProfilePhotoUpdateFormPopup;
