import { Button } from "@mui/material";
import React, { useContext } from "react";
import * as api from "../utils/apiClient";
import { deleteCookie } from "../utils/cookies";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import { MdDashboard } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import { FaList, FaMoneyCheckAlt } from "react-icons/fa";
import { IoIosContact } from "react-icons/io";
import { GiFamilyTree } from "react-icons/gi";
import userPic from "../assets/profile.png";
import CustomTreeView from "./ui/CustomTreeView";

const Sidebar = () => {
  const { setIsLogin, profile, isLogin } = useContext(AuthContext);
  const adminRoutes = [
    {
      name: "Products",
      path: "/products/list",
      isAuth: "admin",
      children: [
        {
          name: "Create Product",
          path: "/products/create",
          icon: <MdDashboard />,
        },
      ],
    },
  ];
  const distributorRoutes = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <MdDashboard />,
    },
    {
      name: "My Income",
      path: "/income",
      icon: <FaMoneyCheckAlt />,
    },
    {
      name: "Users",
      path: "/users",
      icon: <LuUsers />,
    },
    {
      name: "My Account",
      path: "/account",
      icon: <IoIosContact />,
    },
    {
      name: "Distributors",
      path: "/distributors",
      // icon: "none",
      children: [
        {
          name: "Tree View",
          path: "/distributors",
          icon: <GiFamilyTree className="rotate-180" />,
        },
        {
          name: "List View",
          path: "/distributors/list",
          icon: <FaList />,
        },
      ],
    },
  ];
  const logout = async () => {
    try {
      const res = await api.logOut();
      if (res) {
        deleteCookie("access_token");
        deleteCookie("refresh_token");
        toast.success("Logout successfully");
        setIsLogin(false);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="w-full h-full">
      <div className="flex flex-col">
        {/* Profile (image & name) */}
        <div className="flex items-center gap-3 bg-white px-2 py-3">
          <img
            src={profile.profile_pic ? profile.profile_pic : userPic}
            alt="profile"
            className="w-10 h-10 rounded-full"
          />
          <div>
            <p className="text-xs">Hello,</p>
            <h4 className="font-medium capitalize">
              {isLogin && profile?.user_name ? profile?.user_name : "Guest"}
            </h4>
          </div>
        </div>
        {/* Routes */}
        <div>
          {distributorRoutes?.map((route, index) => (
            <CustomTreeView key={index} root={route} />
          ))}
          {adminRoutes?.map((route, index) => (
            <div
              className={profile?.role === route?.isAuth ? "block" : "hidden"}
            >
              <CustomTreeView key={index} root={route} />
            </div>
          ))}
        </div>
        {/* Logout */}
        <div className="px-3 py-2 w-full">
          <Button onClick={logout} variant="contained" color="secondary">
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
