import React, { useState } from 'react';

import { FiMoreVertical } from 'react-icons/fi';

import { IoMdTrendingUp } from 'react-icons/io';

function IncomeCard({
  total,
  number,
  percent,
  time,
  icon,
  color,
  accentColor,
  iconColor,
  percentColor,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div>
      <div className="relative h-44 rounded-lg overflow-hidden">
        {/* Background with gradient */}
        <div className={`absolute inset-0 ${accentColor}  rounded-lg`}>
          <div
            className={`absolute inset-0 bg-gradient-to-br ${color} opacity-50 rounded-lg`}
          ></div>

          {/* Large trending up arrow in the background */}
          <div
            className={`absolute bottom-[-2px] ${accentColor} opacity-10 transform rotate-6`}
          >
            <IoMdTrendingUp size={150} />
          </div>
        </div>

        {/* Content */}
        <div className="relative z-10 text-white p-4 h-full flex flex-col justify-between">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-xl font-semibold mb-1">{total}</h2>
              <p className="text-4xl font-bold">{number}</p>
            </div>
            <div className={`${iconColor} bg-opacity-50 p-3 rounded-md`}>
              {icon}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span
                className={`text-sm font-semibold ${percentColor} bg-opacity-50 p-1 rounded-md`}
              >
                {percent}
              </span>
              <span className="text-md ml-1">{time}</span>
            </div>
            <div className="relative">
              <button onClick={toggleDropdown} className="focus:outline-none">
                <FiMoreVertical size={16} />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 z-30 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomeCard;
