import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as api from "../../utils/apiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import TopHeroSection from "../../components/sections/TopHeroSection";

const ContactUsPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name is too Short!")
      .max(50, "Name is too Long!")
      .required("Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    message: Yup.string()
      .min(10, "Message is too short")
      .required("Message is required"),
  });
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const handleForm = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await api.CreateContact(values);
      if (data) {
        toast.success(data.message);
        navigate("/");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };
  const handleSubmit = (values, { setSubmitting }) => {
    handleForm(values);
    setSubmitting(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <TopHeroSection title="Contact Us" />
      <div className="px-5 sm:px-10 py-10 grid grid-cols-1 sm:grid-cols-2">
        {/* --------------- contact information section -------------*/}
        <div className="">
          <h2 className="text-2xl sm:text-5xl font-medium italic text-black/70 text-wrap">
            Get in touch with us
          </h2>
          <ul className="flex flex-col gap-5 pt-5 sm:pt-10">
            <li className="flex gap-3 items-center">
              <a href="mailto:team@msinternational.org.in">
                <img className="w-12 h-12" src="./mail.png" alt="Email" />
              </a>
              <a href="mailto:team@msinternational.org.in">
                team@msinternational.org.in
              </a>
            </li>
            <li className="flex gap-3 items-center">
              <a href="tel:+918874863903">
                <img className="w-12 h-12" src="./phone.png" alt="Phone" />
              </a>
              <div className="flex flex-col gap-0.5">
                <a href="tel:+918874863903">+91 8874863903</a>
                <a href="tel:+919696664707">+91 9696664707</a>
              </div>
            </li>
            <li className="flex gap-3 items-center">
              <a href="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3612.2791988493505!2d82.55825837531302!3d25.126249977756725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDA3JzM0LjUiTiA4MsKwMzMnMzkuMCJF!5e0!3m2!1sen!2sin!4v1727867755750!5m2!1sen!2sin">
                <img className="w-12 h-12" src="./map.png" alt="Address" />
              </a>
              <div>
                <a href="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3612.2791988493505!2d82.55825837531302!3d25.126249977756725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDA3JzM0LjUiTiA4MsKwMzMnMzkuMCJF!5e0!3m2!1sen!2sin!4v1727867755750!5m2!1sen!2sin">
                  Shir Ram Puram Colony,
                </a>
                <p className="break-all text-sm text-wrap">
                  Gandhi Ghat Mirzapur - 231001
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="bg-white pt-5 sm:pt-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              isValid,
              values,
              isSubmitting,
              handleChange,
            }) => (
              <Form>
                <Box
                  component="div"
                  className="flex flex-col gap-5 w-full sm:w-2/3 mx-auto"
                >
                  <Field
                    as={TextField}
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    placeholder="Please enter your name"
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                    label="Name"
                    variant="outlined"
                    size="small"
                    color="success"
                  />
                  <Field
                    as={TextField}
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    placeholder="Please enter your email"
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    label="Email"
                    variant="outlined"
                    size="small"
                    color="success"
                  />
                  <Field
                    as={TextField}
                    name="message"
                    id="message"
                    onChange={handleChange}
                    placeholder="I'm interested in learning more about..."
                    error={touched.message && errors.message}
                    helperText={touched.message && errors.message}
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={4}
                    size="small"
                    color="success"
                  />
                  <div className="flex justify-end text-end">
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      color="secondary"
                      disabled={
                        !values.name ||
                        !values.email ||
                        !values.message ||
                        !isValid ||
                        isSubmitting
                      }
                    >
                      Send Message
                    </Button>
                  </div>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* Map */}
      <div className="border-none h-[400px]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3612.2791988493505!2d82.55825837531302!3d25.126249977756725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDA3JzM0LjUiTiA4MsKwMzMnMzkuMCJF!5e0!3m2!1sen!2sin!4v1727867755750!5m2!1sen!2sin"
          width="100%"
          height="100%"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Company Location"
        ></iframe>
      </div>
    </>
  );
};

export default ContactUsPage;
