import React, { useEffect, useRef } from "react";
import Music from "../assets/Vandana_shri_Ganesh.mp3";

const Audio = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    const playAudio = async () => {
      try {
        if (audioRef.current) {
          await audioRef.current.play();
        }
      } catch (error) {
        console.error("Autoplay failed:", error);
      }
    };

    playAudio();
  }, []);

  return (
    <audio
      ref={audioRef}
      // src={require("../../assets/audio/autio.mp3")}
      src={Music}
      autoPlay={true}
      preload="auto"
    />
  );
};

export default Audio;
