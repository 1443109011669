import { Button, TextField } from "@mui/material";
import React from "react";

function SearchSection({ formData, setFormData, searchDistributor }) {
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <form action="#" className="flex flex-col gap-3 w-full">
        <div className="w-full sm:w-1/3 m-auto flex items-center gap-3">
          <TextField
            id="user_id"
            label="User ID"
            value={formData.user_id}
            variant="outlined"
            fullWidth={true}
            size="small"
            color="info"
            required
            name="user_id"
            onChange={handleChange}
          />
          <div className="">
            <Button
              disabled={!formData?.user_id?.length > 0}
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                setFormData({
                  ...formData,
                  user_id: "",
                });
                searchDistributor();
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default SearchSection;
