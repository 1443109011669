import React, { useState } from "react";

const TreeNode = ({ node, x, y, level, getATree, position = "root" }) => {
  const [isOpenForDistributorsInfo, setIsOpenForDistributorsInfo] =
    useState(false);
  const childSpacing = 180; // Increased spacing for better separation
  const levelSpacing = 150;

  // Safely handle undefined or null nodes
  if (!node) {
    return null;
  }

  const renderChildren = () => {
    if (!node.children || node.children.length === 0) {
      return null;
    }

    return node.children.map((child, index) => {
      // Calculate x position based on the number of children
      const totalWidth = (node.children.length - 1) * childSpacing;
      const childX = x - totalWidth / 2 + index * childSpacing;
      const childY = y + levelSpacing;

      return (
        <g key={child.user_id}>
          <line x1={x} y1={y} x2={childX} y2={childY} stroke="#ccc" />
          <TreeNode
            node={child}
            x={childX}
            y={childY}
            level={level + 1}
            getATree={getATree}
            position={child.position || (index % 2 === 0 ? "left" : "right")}
          />
        </g>
      );
    });
  };

  return (
    <g>
      {/* Popup with High Z-Index */}
      {isOpenForDistributorsInfo && (
        <foreignObject
          x={x - 225} // Centered popup
          y={y - 130} // Positioned above the node
          width={500}
          height={250}
          className="absolute"
          style={{ zIndex: 9999 }}
        >
          <div
            className="bg-white p-5 rounded-lg shadow-2xl border border-gray-200 
                        absolute top-0 left-0 z-[9999] 
                        transform scale-100 transition-all duration-300"
            style={{
              transformOrigin: "bottom center",
              boxShadow: "0 10px 15px rgba(0,0,0,0.2)",
            }}
          >
            <div className="flex items-center mb-2">
              {node?.distributor_info?.image ? (
                <img
                  src={node.distributor_info.image}
                  alt={`MS-${node?.user_id}`}
                  className="w-12 h-12 rounded-full mr-3 object-cover"
                />
              ) : (
                <img
                  src={require("../../assets/profile.png")}
                  alt={`MS-${node?.user_id}`}
                  className="w-12 h-12 rounded-full mr-3 object-cover"
                />
              )}
              <h3 className="font-bold text-lg">
                {`MS-${node?.user_id ?? "N/A"}`}
              </h3>
            </div>
            <ul className="space-y-1">
              <li className="text-lg">
                <span className="font-medium">Name:</span>{" "}
                {node?.distributor_info?.name ?? "N/A"}
              </li>
              <li className="text-lg">
                <span className="font-medium">Sponsor ID:</span>{" "}
                {`MS-${node?.sponsor_id ?? "N/A"}`}
              </li>
              <li className="text-lg">
                <span className="font-medium">Position:</span>
                {node?.position === "left"
                  ? "ORG-1"
                  : node?.position === "right"
                  ? "ORG-2"
                  : "ROOT"}
              </li>
              <li className="text-lg">
                <span className="font-medium">Gender:</span>{" "}
                {node?.distributor_info?.gender ?? "N/A"}
              </li>
              <li className="text-lg">
                <span className="font-medium">Total BV:</span>{" "}
                {node?.distributor_info?.total_bv ?? "N/A"}
              </li>
            </ul>
          </div>
        </foreignObject>
      )}

      {/* Node Circle */}

      <defs>
        <clipPath id={`circleClip-${node?.user_id}`}>
          <circle cx={x} cy={y} r={30} />
        </clipPath>
      </defs>
      {/* if image than showing data */}
      {node?.distributor_info?.profile_pic ? (
        <image
          x={x - 50}
          y={y - 50}
          width={100}
          height={100}
          href={node.distributor_info.profile_pic}
          clipPath={`url(#circleClip-${node?.user_id})`}
          preserveAspectRatio="xMidYMid slice"
          className="cursor-pointer object-cover object-center"
          onMouseEnter={() => setIsOpenForDistributorsInfo(true)}
          onMouseLeave={() => setIsOpenForDistributorsInfo(false)}
          onClick={() => {
            if (getATree) {
              getATree(node?.user_id);
            }
          }}
        />
      ) : (
        <circle
          cx={x}
          cy={y}
          r={30}
          fill={
            position === "left"
              ? "#3B82F6"
              : position === "right"
              ? "#10B981"
              : "#0D9488"
          }
          className="cursor-pointer mx-auto"
          onMouseEnter={() => setIsOpenForDistributorsInfo(true)}
          onMouseLeave={() => setIsOpenForDistributorsInfo(false)}
          onClick={() => {
            if (getATree) {
              getATree(node?.user_id);
            }
          }}
        />
      )}

      <text
        x={x}
        y={y}
        textAnchor="middle"
        dy=".3em"
        fontSize={12}
        className={`font-semibold pointer-events-none ${
          node?.distributor_info?.profile_pic ? "fill-black" : "fill-white"
        }`}
      >
        {`MS-${node?.user_id ?? "N/A"}`}
      </text>

      {/* Render Children */}
      {renderChildren()}
    </g>
  );
};

const UserTreeViewCard = ({ data, getUsersTree }) => {
  // Ensure data is not undefined
  const safeData = data || {};

  return (
    <svg
      width="100%"
      className="h-[1000px] sm:h-screen w-full sm:w-full overflow-auto"
      // className="w-full h-screen"
      preserveAspectRatio="xMidYMid meet" // Ensures proper scaling
      // viewBox="0 0 2000 2000"
      // viewBox={`0 1000 ${2000} ${2000}`}
      // style={{

      //   viewBox: window.innerWidth < 768 ? "0 0 0 0" : "0 0 0 0",
      // }}
    >
      <TreeNode
        node={safeData}
        x={230} // Centered for wide viewbox
        y={100}
        level={0}
        getATree={(id) => {
          getUsersTree(id);
        }}
      />
    </svg>
  );
};

export default UserTreeViewCard;
