import React from "react";
import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm";

const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPasswordPage;
