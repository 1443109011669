import React, { useContext, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { deleteCookie } from "../../utils/cookies";
import * as api from "../../utils/apiClient";

const Navbar = () => {
  const { isLogin, setIsLogin, profile } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => setIsOpen(!isOpen);

  const links = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Products",
      link: "/products",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Contact",
      link: "/contact_us",
    },
  ];
  const authLinks = [
    {
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      name: "Users",
      link: "/users",
    },
    {
      name: "My Income",
      link: "/income",
    },
    {
      name: "My Account",
      link: "/account",
    },
    {
      name: "Distributors",
      link: "/distributors",
    },
  ];
  const logout = async () => {
    try {
      const res = await api.logOut();
      if (res) {
        deleteCookie("access_token");
        deleteCookie("refresh_token");
        toast.success("Logout successfully");
        setIsLogin(false);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <nav className="relative">
      <div
        className={`flex px-5 sm:px-0 ${
          !isLogin ? "justify-between" : "justify-center sm:justify-between"
        } items-center py-2 text-sm bg-white max-w-[1440px] container mx-auto`}
      >
        <div className="hidden sm:flex items-center gap-2">
          <a
            href="tel:+918874863903"
            target="_blank"
            rel="noopener noreferrer"
          >{`+91 8874863903`}</a>
          |
          <a
            href="mailto:team@msinternational.org.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            team@msinternational.org.in
          </a>
        </div>
        <div>
          <h5 className="font-medium text-lg">{`श्री गणेशाय नमः`}</h5>
        </div>
        <div
          className={`uppercase ${
            !isLogin ? "flex" : "hidden sm:flex"
          } items-center gap-2 font-medium`}
        >
          <Link
            to={`/signup?sponsor_id=MS-${
              Object.keys(profile).length > 0 ? profile?.id : "01"
            }`}
          >
            Register
          </Link>
          <Link to="/login">Login</Link>
        </div>
      </div>
      <div className="bg-black bg-opacity-50 text-white">
        <div className="px-5 md:px-0 max-w-[1440px] container mx-auto">
          <div className="flex items-center justify-between h-20">
            <div
              className="flex-shrink-0 cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={require("../../assets/new_logo.png")}
                alt="logo"
                className="h-14 w-14 rounded-full"
              />
            </div>
            <div className="hidden md:block">
              <div className="flex items-baseline gap-5">
                {links?.map((link) => {
                  return (
                    <Link
                      key={link.name}
                      to={link.link}
                      className="text-lg font-normal"
                    >
                      {link.name}
                    </Link>
                  );
                })}
                {isLogin && (
                  <Link to={"/dashboard"} className="text-lg font-normal">
                    Dashboard
                  </Link>
                )}
              </div>
            </div>
            <div className="hidden md:block">
              <div className="flex items-center">
                <div>
                  {isLogin ? (
                    <div className="relative">
                      <div
                        className={
                          isProfileOpen
                            ? "absolute top-10 -left-16 z-50"
                            : "hidden"
                        }
                      >
                        <ul
                          className="bg-white w-28 rounded-md flex flex-col gap-1 m-auto text-black p-3"
                          onMouseLeave={() => setIsProfileOpen(false)}
                          onMouseEnter={() => setIsProfileOpen(true)}
                        >
                          <li
                            className="cursor-pointer hover:text-neutral-4"
                            onClick={() => {
                              setIsProfileOpen(false);
                              navigate("/income");
                            }}
                          >
                            My Income
                          </li>
                          <li
                            className="cursor-pointer hover:text-neutral-4"
                            onClick={() => {
                              setIsProfileOpen(false);
                              navigate("/account");
                            }}
                          >
                            My Account
                          </li>
                          <li
                            className="cursor-pointer text-error"
                            onClick={() => {
                              logout();
                              setIsOpen(false);
                              navigate("/login");
                            }}
                          >
                            Logout
                          </li>
                        </ul>
                      </div>
                      {profile?.profile_pic ? (
                        <img
                          src={profile?.profile_pic}
                          onMouseEnter={() => setIsProfileOpen(true)}
                          onMouseLeave={() => setIsProfileOpen(false)}
                          alt="profile"
                          className="w-10 h-10 rounded-full cursor-pointer"
                        />
                      ) : (
                        <img
                          src={require("../../assets/profile.png")}
                          onMouseEnter={() => setIsProfileOpen(true)}
                          onMouseLeave={() => setIsProfileOpen(false)}
                          alt="profile"
                          className="w-10 h-10 rounded-full cursor-pointer"
                        />
                      )}
                    </div>
                  ) : (
                    <Button
                      onClick={() => navigate("/login")}
                      variant="contained"
                      color="secondary"
                    >
                      Login
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {/* Mobile menu button */}
            <div className="flex items-center gap-2 md:hidden">
              {isLogin && (
                <div className="relative">
                  <div
                    className={
                      isProfileOpen ? "absolute top-10 -left-16 z-50" : "hidden"
                    }
                  >
                    <ul
                      className="bg-white w-28 rounded-md flex flex-col gap-1 m-auto text-black p-3"
                      onMouseLeave={() => setIsProfileOpen(false)}
                      onMouseEnter={() => setIsProfileOpen(true)}
                    >
                      <li
                        className="cursor-pointer hover:text-neutral-4"
                        onClick={() => {
                          setIsProfileOpen(false);
                          navigate("/income");
                        }}
                      >
                        My Income
                      </li>
                      <li
                        className="cursor-pointer hover:text-neutral-4"
                        onClick={() => {
                          setIsProfileOpen(false);
                          navigate("/account");
                        }}
                      >
                        My Account
                      </li>
                      <li
                        className="cursor-pointer text-error"
                        onClick={() => {
                          logout();
                          setIsOpen(false);
                          navigate("/login");
                        }}
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                  {profile?.profile_pic ? (
                    <img
                      src={profile?.profile_pic}
                      onMouseEnter={() => setIsProfileOpen(true)}
                      onMouseLeave={() => setIsProfileOpen(false)}
                      alt="profile"
                      className="w-10 h-10 rounded-full cursor-pointer"
                    />
                  ) : (
                    <img
                      src={require("../../assets/profile.png")}
                      onMouseEnter={() => setIsProfileOpen(true)}
                      onMouseLeave={() => setIsProfileOpen(false)}
                      alt="profile"
                      className="w-10 h-10 rounded-full cursor-pointer"
                    />
                  )}
                </div>
              )}
              <div className="-mr-2 flex md:hidden z-50">
                <button
                  onClick={toggleDrawer}
                  className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-blue-600 focus:bg-blue-600 focus:outline-none"
                >
                  {isOpen ? (
                    <IoMdClose className="block h-7 w-7" />
                  ) : (
                    <MdOutlineMenu className="block h-7 w-7" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Left Form for small devices */}
        {isOpen && (
          <div className="fixed z-50 inset-y-0 left-0 w-[70%] bg-gray-900 p-5 transform transition-transform duration-300 ease-in-out md:hidden">
            <div className="flex flex-col space-y-0">
              <div className="flex gap-3 items-center border border-neutral-4 p-2 rounded">
                <div>
                  {profile?.profile_pic ? (
                    <img
                      src={profile?.profile_pic}
                      onMouseEnter={() => setIsProfileOpen(true)}
                      onMouseLeave={() => setIsProfileOpen(false)}
                      alt="profile"
                      className="w-14 h-14 rounded-full cursor-pointer"
                    />
                  ) : (
                    <img
                      src={require("../../assets/profile.png")}
                      onMouseEnter={() => setIsProfileOpen(true)}
                      onMouseLeave={() => setIsProfileOpen(false)}
                      alt="profile"
                      className="w-14 h-14 rounded-full cursor-pointer"
                    />
                  )}
                </div>
                <div>
                  <h3 className="font-semibold">{profile?.user_name}</h3>
                  <a
                    href={`tel:+91 ${profile?.phone_number}`}
                  >{`+91 ${profile?.phone_number}`}</a>
                  <p>
                    <span className="font-semibold">User ID: </span>
                    {`MS-${profile?.id}`}
                  </p>
                </div>
              </div>
              {links?.map((link) => {
                return (
                  <Link
                    key={link.name}
                    to={link.link}
                    className="block p-2 rounded-md font-medium hover:bg-secondary duration-300"
                    onClick={() => setIsOpen(false)}
                  >
                    {link.name}
                  </Link>
                );
              })}
              {authLinks?.map((link) => {
                return (
                  <Link
                    key={link.name}
                    onClick={() => setIsOpen(false)}
                    to={link.link}
                    className="block p-2 rounded-md font-medium hover:bg-secondary duration-300"
                  >
                    {link.name}
                  </Link>
                );
              })}
              {!isLogin ? (
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    navigate("/login");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Login
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    logout();
                    setIsOpen(false);
                    navigate("/login");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Logout
                </Button>
              )}
            </div>
          </div>
        )}

        {/* Overlay to close the left form when clicking outside */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-blue-800 bg-opacity-50 z-20 md:hidden"
            onClick={() => setIsOpen(false)}
          ></div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
