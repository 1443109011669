import React from "react";

const ProductCardSkeleton = () => {
  return (
    <div className="shadow rounded p-1 bg-white border-2 animate-pulse">
      {/* Image placeholder */}
      <div className="w-full h-48 rounded bg-gray-300" />

      <div className="p-2">
        {/* Title placeholder */}
        <div className="h-6 bg-gray-300 rounded w-3/4 mb-2" />

        {/* Price placeholder */}
        <div className="h-6 bg-gray-300 rounded w-1/4" />
      </div>

      {/* Button placeholder */}
      <div className="flex justify-end p-2">
        <div className="h-10 bg-gray-300 rounded w-28" />
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
