import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  FormControlLabel,
  CardContent,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import * as api from "../../utils/apiClient";
import Loading from "../Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosShareAlt } from "react-icons/io";

// Styled components
const StyledForm = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: "900px",
  margin: "15px auto",
}));

const PhotoUploadSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PhotoPreview = styled("img")({
  width: "120px",
  height: "120px",
  objectFit: "cover",
  borderRadius: "8px",
  marginBottom: "16px",
});

const Placeholder = styled("div")({
  width: "120px",
  height: "120px",
  border: "2px dashed #ddd",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f0f0f0",
  color: "#aaa",
  fontSize: "16px",
  marginBottom: "16px",
});

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(-1),
}));

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  father_name: Yup.string().required("Father's Name is required"),
  address: Yup.string().required("Address is required"),
  village: Yup.string().required("Village is required"),
  post_office: Yup.string().required("Post Office is required"),
  district: Yup.string().required("District is required"),
  pin_code: Yup.string()
    .matches(/^[0-9]{6}$/, "Invalid pincode")
    .required("Pincode is required"),
  state: Yup.string().required("State is required"),
  phone_no: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid phone number")
    .required("phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date().required("Date of Birth is required"),
  caste: Yup.string().required("Caste is required"),
  religion: Yup.string().required("Religion is required"),
  pan_no: Yup.string().matches(
    /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    "Invalid PAN number"
  ),
  aadhar_no: Yup.string()
    .matches(/^[0-9]{12}$/, "Invalid Aadhaar number")
    .required("Aadhaar number is required"),
  educational_qualification: Yup.string().required(
    "Educational qualification is required"
  ),
  professional_qualification: Yup.string().required(
    "Professional qualification is required"
  ),
  work_experience: Yup.string().required("Work experience is required"),
  current_job: Yup.string().required("Current job is required"),
  // sponsor_id: Yup.string().required("Reference ID is required"),
  declaration: Yup.boolean()
    .oneOf([true], "You must accept the declaration")
    .required("You must accept the declaration"),
});

const CustomerForm = () => {
  const [photoPreview, setPhotoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sponsorDetail, setSponsorDetail] = useState({});
  const [params] = useSearchParams();
  const [sponsorId, setSponsorId] = useState(params.get("sponsor_id") || "");
  // eslint-disable-next-line no-unused-vars
  const [FillUpFormBy, setFillUpFormBy] = useState(
    params.get("fill_up_form_by") || ""
  );
  const navigate = useNavigate();
  const handlePhotoChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("photo", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSponsorChange = (event, setFieldValue) => {
    setFieldValue("sponsor_id", event.target.value);
    navigate(`/signup?sponsor_id=${event.target.value}`);
    setSponsorId(event.target.value);
  };
  useEffect(() => {
    // TODO: Check ki Sponsor ID exist or not if not than form disabled condition(Object.keys(sponsorDetail).length> 0 && params.get("sponsor_id"))
    const getSponsorDetail = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.sponsorDetails(
          params.get("sponsor_id").split("MS-")[1]
            ? params.get("sponsor_id").split("MS-")[1]
            : params.get("sponsor_id")
        );
        if (data) {
          setSponsorDetail(data.user);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        setSponsorDetail({});
      }
    };
    getSponsorDetail();
  }, [params]);

  const handleForm = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("aadhar_no", values.aadhar_no);
    formData.append("address", values.address);
    formData.append("caste", values.caste);
    formData.append("current_job", values.current_job);
    formData.append("district", values.district);
    formData.append("dob", values.dob);
    formData.append(
      "education_qualification",
      values.educational_qualification
    );
    formData.append("email", values.email);
    formData.append("father_name", values.father_name);
    formData.append("gender", values.gender);
    formData.append("name", values.name);
    formData.append("pan_no", values.pan_no);
    formData.append("phone_no", values.phone_no);
    formData.append("pin_code", values.pin_code);
    formData.append("post_office", values.post_office);
    formData.append(
      "profession_qualification",
      values.professional_qualification
    );
    formData.append("religion", values.religion);
    formData.append(
      "distributer_id",
      sponsorId.split("MS-")[1] ? sponsorId.split("MS-")[1] : sponsorId
    );
    formData.append("state", values.state);
    formData.append("village", values.village);
    formData.append("work_experience", values.work_experience);
    formData.append("profile_pic", values.photo);
    formData.append(
      "reset_password_ui_url",
      `${api.siteBaseURL}/reset_password`
    );
    formData.append("role", "customer");
    try {
      const { data } = await api.signUp(formData);
      if (data) {
        toast.success(data.message);
        navigate("/");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    handleForm(values);
    setSubmitting(false);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: FillUpFormBy
            ? "Distributor Application Form"
            : "महिला कल्याण सेवा संस्थान",
          text: FillUpFormBy
            ? "Distributor Application Form"
            : "महिला कल्याण सेवा संस्थान",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing the page:", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          aadhar_no: "",
          address: "",
          caste: "",
          current_job: "",
          district: "",
          dob: "",
          educational_qualification: "",
          email: "",
          father_name: "",
          gender: "",
          name: "",
          pan_no: "",
          phone_no: "",
          pin_code: "",
          post_office: "",
          professional_qualification: "",
          religion: "",
          sponsor_id: "",
          state: "",
          village: "",
          work_experience: "",
          photo: null,
          declaration: false,
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <StyledForm>
            <CardContent>
              <Form className="flex flex-col gap-3 relative">
                <h1 className="text-3xl flex justify-center font-semibold text-neutral-5">
                  {FillUpFormBy
                    ? "Distributor Application Form"
                    : `महिला कल्याण सेवा संस्थान`}
                </h1>
                <div className="text-secondary">
                  <IoIosShareAlt
                    size={35}
                    onClick={handleShare}
                    className="absolute sm:-top-5 -top-11 -right-3 sm:right-0 cursor-pointer"
                  />
                  <p className="font-medium text-sm text-neutral-4 absolute sm:top-3 -top-5 sm:right-2 -right-3">
                    Share
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row gap-3">
                  {/* Photo */}
                  <div className="w-full sm:w-[30%]">
                    <PhotoUploadSection>
                      {photoPreview ? (
                        <PhotoPreview src={photoPreview} alt="Preview" />
                      ) : (
                        <Placeholder>No Photo</Placeholder>
                      )}
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="photo-upload"
                        type="file"
                        name="photo"
                        onChange={(event) =>
                          handlePhotoChange(event, setFieldValue)
                        }
                      />
                      <label htmlFor="photo-upload">
                        <Button
                          variant="contained"
                          color="secondary"
                          component="span"
                          size="small"
                        >
                          Upload Photo
                        </Button>
                      </label>
                    </PhotoUploadSection>
                  </div>
                  {/* Name, Father's Name and Sponsor ID */}
                  <div className="w-full sm:w-[70%] flex flex-col gap-3">
                    <div className="grid gris-cols-1 sm:grid-cols-2 gap-3 w-full">
                      <Field
                        as={TextField}
                        fullWidth
                        size={"small"}
                        color="info"
                        name="distributer_id"
                        value={sponsorId} //TODO: changes this config
                        onChange={(event) =>
                          handleSponsorChange(event, setFieldValue)
                        }
                        label="Sponsor ID*"
                        error={
                          touched.distributer_id &&
                          Boolean(errors.distributer_id)
                        }
                        helperText={
                          touched.distributer_id && errors.distributer_id
                        }
                      />
                      <div className="flex items-center gap-3">
                        <Avatar
                          src={sponsorDetail?.profile_pic}
                          alt="logo"
                        ></Avatar>
                        <ul className="flex flex-col gap-1">
                          <li>
                            Name:-{" "}
                            <span className="capitalize font-semibold">
                              {sponsorDetail?.user_name}
                            </span>
                          </li>
                          <li>
                            Phone Number:-{" "}
                            <span className="capitalize font-semibold">
                              {sponsorDetail?.phone_number}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="phone_no"
                      label="Phone Number"
                      error={touched.phone_no && Boolean(errors.phone_no)}
                      helperText={touched.phone_no && errors.phone_no}
                    />
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="email"
                      label="Email"
                      type="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </div>
                </div>
                {/* Name, Father's Name */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="name"
                      label="Name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      size={"small"}
                      color="info"
                      fullWidth
                      name="father_name"
                      label="Father's Name"
                      error={touched.father_name && Boolean(errors.father_name)}
                      helperText={touched.father_name && errors.father_name}
                    />
                  </div>
                </div>
                {/* Aadhaar Number, PAN Number */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="aadhar_no"
                      label="Aadhaar Number"
                      error={touched.aadhar_no && Boolean(errors.aadhar_no)}
                      helperText={touched.aadhar_no && errors.aadhar_no}
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="pan_no"
                      label="PAN Number"
                      error={touched.pan_no && Boolean(errors.pan_no)}
                      helperText={touched.pan_no && errors.pan_no}
                    />
                  </div>
                </div>
                {/* Date of Birth, Caste, Gender */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="dob"
                      label="Date of Birth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      error={touched.dob && Boolean(errors.dob)}
                      helperText={touched.dob && errors.dob}
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="caste"
                      label="Caste"
                      error={touched.caste && Boolean(errors.caste)}
                      helperText={touched.caste && errors.caste}
                    />
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="gender-label">Gender</InputLabel>
                      <Field
                        as={Select}
                        size={"small"}
                        color="info"
                        labelId="gender-label"
                        name="gender"
                        label="Gender"
                        error={touched.gender && Boolean(errors.gender)}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        {/* <MenuItem value="other">Other</MenuItem> */}
                      </Field>
                      {touched.gender && errors.gender && (
                        <Typography color="error" variant="body2">
                          {errors.gender}
                        </Typography>
                      )}
                    </FormControl>
                  </div>
                </div>
                {/* District, Pincode, State */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="district"
                      label="District"
                      error={touched.district && Boolean(errors.district)}
                      helperText={touched.district && errors.district}
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="pin_code"
                      label="Pincode"
                      error={touched.pin_code && Boolean(errors.pin_code)}
                      helperText={touched.pin_code && errors.pin_code}
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="state"
                      label="State"
                      error={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  </div>
                </div>
                <div>
                  <Field
                    as={TextField}
                    fullWidth
                    size={"small"}
                    color="info"
                    name="educational_qualification"
                    label="Educational Qualification"
                    error={
                      touched.educational_qualification &&
                      Boolean(errors.educational_qualification)
                    }
                    helperText={
                      touched.educational_qualification &&
                      errors.educational_qualification
                    }
                  />
                </div>
                {/* Professional Qualification, Religion */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="professional_qualification"
                      label="Professional Qualification"
                      error={
                        touched.professional_qualification &&
                        Boolean(errors.professional_qualification)
                      }
                      helperText={
                        touched.professional_qualification &&
                        errors.professional_qualification
                      }
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="religion"
                      label="Religion"
                      error={touched.religion && Boolean(errors.religion)}
                      helperText={touched.religion && errors.religion}
                    />
                  </div>
                </div>
                {/* Work Experience and Current Job */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="work_experience"
                      label="Work Experience"
                      error={
                        touched.work_experience &&
                        Boolean(errors.work_experience)
                      }
                      helperText={
                        touched.work_experience && errors.work_experience
                      }
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="current_job"
                      label="Current Job"
                      error={touched.current_job && Boolean(errors.current_job)}
                      helperText={touched.current_job && errors.current_job}
                    />
                  </div>
                </div>
                {/* Village, Post Office */}
                <div className="flex flex-col sm:flex-row gap-3">
                  <div className="w-full sm:w-[70%]">
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="village"
                      label="Village"
                      error={touched.village && Boolean(errors.village)}
                      helperText={touched.village && errors.village}
                    />
                  </div>
                  <div className="w-full sm:w-[30%]">
                    <Field
                      as={TextField}
                      fullWidth
                      size={"small"}
                      color="info"
                      name="post_office"
                      label="Post Office"
                      error={touched.post_office && Boolean(errors.post_office)}
                      helperText={touched.post_office && errors.post_office}
                    />
                  </div>
                </div>
                <div>
                  <Field
                    as={TextField}
                    fullWidth
                    size={"small"}
                    color="info"
                    name="address"
                    label="Full Address"
                    multiline
                    rows={3}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </div>
                {/* Declaration */}
                <div>
                  <Typography variant="body1" gutterBottom>
                    Declaration:
                  </Typography>
                  <Typography variant="body2" paragraph>
                    I hereby declare that all the information provided in this
                    form is true and correct to the best of my knowledge. I
                    understand that any false or misleading information may
                    result in the rejection of my application or termination of
                    any services provided.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Field as={Checkbox} name="declaration" color="info" />
                    }
                    label="I agree to the above declaration"
                  />
                  {touched.declaration && errors.declaration && (
                    <Typography color="error" variant="body2">
                      {errors.declaration}
                    </Typography>
                  )}
                </div>
                {/* Submit Button */}
                <div>
                  <SubmitButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={!values.declaration}
                  >
                    Submit
                  </SubmitButton>
                </div>
              </Form>
            </CardContent>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default CustomerForm;
