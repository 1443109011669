import React, { useState } from "react";
import ProtectedLayout from "../../components/layouts/ProtectedLayout";
// import Head from 'next/head';
import { FaChartLine, FaUsers, FaCog } from "react-icons/fa";
import IncomeCard from "../../components/card/IncomeCard";
import { FaUserCircle } from "react-icons/fa";

function MyIncomePage() {
  const [performance, setPerformance] = useState(15);
  // console.log(performance);
  // Example state for performance
  const [rank, setRank] = useState("Gold");
  // console.log(rank); // Example state for rank

  const handleChange = () => {
    // Update state or handle button click logic here
    alert("all level show and highlight show your position level another page");
    setPerformance(performance + 5); // Example: increment performance
    setRank(rank === "Gold" ? "Platinum" : "Gold"); // Example: toggle rank
  };
  // const handleChange = () => {
  //   alert(
  //     '  '
  //   );
  // };
  return (
    <ProtectedLayout>
      <div>
        <div className="min-h-screen bg-gray-100">
          {/* <Head>
            <title>My Income Dashboard</title>
            <link rel="icon" href="/favicon.ico" />
          </Head> */}

          <main className="container m-3">
            <header className="flex justify-between items-center mb-8">
              <h1 className="text-xl sm:text-2xl font-semibold text-black">
                My Income Dashboard
              </h1>
              {/* <select className="p-2 border rounded">
                <option>This Month</option>
                <option>Last Month</option>
                <option>This Year</option>
              </select> */}
            </header>

            <div className="flex flex-col sm:flex-row gap-3">
              <div className="w-full sm:w-[40%] flex flex-col gap-3">
                <IncomeCard
                  total={"Total Commission"}
                  number={"277"}
                  percent={"+95%"}
                  time={"Last Month"}
                  icon={<FaUserCircle size={24} />}
                  color={"from-emerald-700 to-green-300"}
                  accentColor={"bg-emerald-500"}
                  iconColor={"bg-emerald-700"}
                  percentColor={"bg-emerald-900"}
                  IncomeCard
                />
                <IncomeCard
                  total={"Performance Bonus"}
                  number={"290"}
                  percent={"+90%"}
                  time={"Two Month"}
                  icon={<FaUserCircle size={24} />}
                  color={"from-rose-700 to-red-300"}
                  accentColor={"bg-rose-500"}
                  iconColor={"bg-rose-700"}
                  percentColor={"bg-rose-900"}
                />
              </div>
              <div className="w-full sm:w-[60%] bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Income Graph</h2>
                <div className="h-64 bg-gray-200 flex items-center justify-center">
                  <p>Graph Placeholder</p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-5">
              <button
                onClick={handleChange}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow transition-transform transform hover:scale-105"
              >
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h2 className="text-xl font-semibold mb-4 text-blue-700">
                    Performance
                  </h2>
                  <p className="text-lg font-medium text-black">
                    This Month: +{performance}%
                  </p>
                  <p className="text-lg font-medium text-black">Rank:{rank}</p>
                </div>
              </button>
              <button
                onClick={handleChange}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow transition-transform transform hover:scale-105"
              >
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h2 className="text-xl font-semibold mb-4 text-blue-700">
                    Performance
                  </h2>
                  <p className="text-lg font-medium text-black">
                    This Month: +{performance}%
                  </p>
                  <p className="text-lg font-medium text-black">Rank:{rank}</p>
                </div>
              </button>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4 text-green-700">
                  Quick Actions
                </h2>
                <div className="flex justify-around">
                  <div className="flex flex-col items-center">
                    <FaChartLine className="text-4xl text-blue-500 mb-2" />
                    <span className="text-sm text-gray-700">Analytics</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <FaUsers className="text-4xl text-green-500 mb-2" />
                    <span className="text-sm text-gray-700">Team</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <FaCog className="text-4xl text-gray-500 mb-2" />
                    <span className="text-sm text-gray-700">Settings</span>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </ProtectedLayout>
  );
}

export default MyIncomePage;
