import React, { useEffect, useState } from "react";
import ProductCard from "../../components/card/ProductCard";
import * as api from "../../utils/apiClient";
import Loading from "../../components/Loading";
import ProductCardSkeleton from "../../components/card/ProductCardSkeleton";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const ProductPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([
    "All",
    "Home Care",
    "Personal Care",
    "Health Care",
    "Electronics",
  ]);
  useEffect(() => {
    const getAllUsers = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllPublicProducts();
        if (data) {
          setData(data.products);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <div className="max-w-[1440px] container mx-auto px-5 sm:px-0 py-5">
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-[20%]">
            <div className="flex justify-start sm:justify-center">
              <FormControl>
                <FormLabel
                  className="text-xl font-medium"
                  id="demo-radio-buttons-group-label"
                >
                  Filter By Category
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="All"
                  name="radio-buttons-group"
                >
                  {categories?.map((category, index) => (
                    <FormControlLabel
                      disabled
                      key={index}
                      value={category}
                      control={<Radio color="secondary" />}
                      label={category}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {data.length > 0
              ? data?.map((product, index) => (
                  <ProductCard data={product} key={index} />
                ))
              : [...Array(8)].map((_, index) => (
                  <ProductCardSkeleton key={index} />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
