import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as api from "../../utils/apiClient";
import Loading from "../Loading";
import { toast } from "react-toastify";

function AddOrder({ isOpen, handleClose, userId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [productFormData, setProductFormData] = useState({
    product_id: "",
    quantity: "",
    sell_to: userId,
  });

  useEffect(() => {
    const getAllProduct = async () => {
      setLoading(true);
      try {
        const { data } = await api.getAllProducts();
        if (data) {
          setData(data.products);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getAllProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setProductFormData({
      ...productFormData,
      [event.target.name]: event.target.value + "",
    });
  };
  const AddOrderSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await api.addOrder(productFormData);
      if (data) {
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  return (
    <>
      <React.Fragment>
        {loading && <Loading />}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Add Order
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form className="flex flex-col gap-5 w-full">
              <FormControl fullWidth size="small">
                <InputLabel
                  id="name-label"
                  sx={{ backgroundColor: "white", px: 1 }}
                >
                  Select Product
                </InputLabel>
                <Select
                  labelId="name-label"
                  id="product_id"
                  size="small"
                  color="info"
                  value={productFormData.product_id}
                  label="Name"
                  onChange={handleChange}
                  name="product_id"
                  required
                >
                  {data?.map((item, index) => (
                    <MenuItem key={index} value={item.product_id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                <TextField
                  id="quantity"
                  label="product quantity"
                  variant="outlined"
                  fullWidth={true}
                  value={productFormData.quantity}
                  size="small"
                  color="info"
                  required
                  name="quantity"
                  onChange={handleChange}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                !productFormData?.quantity?.length > 0 ||
                !productFormData?.product_id?.length > 0
              }
              onClick={AddOrderSubmit}
              variant="contained"
              disableElevation
              color="secondary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}

export default AddOrder;
