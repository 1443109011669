import React from "react";

function AwardRecognition() {
  return (
    <div className="mb-5 bg-neutral-2 py-10">
      <p className="text-center text-3xl font-medium py-5">
        Awards & Recognition
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4">
        <div>
          <img
            alt="awards_recognition"
            className="w-52 mx-auto"
            src={require("../../assets/awards_recognition/1.png")}
          />
        </div>
        <div>
          <img
            alt="awards_recognition"
            className="w-52 mx-auto"
            src={require("../../assets/awards_recognition/2.png")}
          />
        </div>
        <div>
          <img
            alt="awards_recognition"
            className="w-52 mx-auto"
            src={require("../../assets/awards_recognition/3.png")}
          />
        </div>
        <div>
          <img
            alt="awards_recognition"
            className="w-52 mx-auto"
            src={require("../../assets/awards_recognition/4.png")}
          />
        </div>
      </div>
    </div>
  );
}

export default AwardRecognition;
