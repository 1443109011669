import React from "react";
import ProductImages from "../../../components/productComponents/ProductImages";
import { useParams } from "react-router-dom";
import ProductCreatingSteps from "../../../components/ui/ProductCreatingSteps";

function ProductImagesPage() {
  const { id } = useParams();

  return (
    <>
      <ProductCreatingSteps step={1} />
      <div className="w-full sm:w-[70%] mx-auto py-10">
        {/* Product Images */}
        <ProductImages productId={id} />
      </div>
    </>
  );
}

export default ProductImagesPage;
