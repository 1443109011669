import React, { useContext, useEffect, useMemo, useState } from "react";
import ProtectedLayout from "../../../components/layouts/ProtectedLayout";
import { UsersCountCard } from "../../../components/card/UsersCountCard";
import { FaUserCheck, FaUserMinus } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as api from "../../../utils/apiClient";
import { formatDateTimeWithAMAndPM } from "../../../utils/DateCalculate";
import Loading from "../../../components/Loading";
import { AuthContext } from "../../../context/AuthContext";

function DistributorsListPage() {
  const [data, setData] = useState([]);
  const { profile } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getAllUsers = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllUsers("distributer");
        console.log(data);
        if (data) {
          setCustomerData({
            ...customerData,
            total: data?.distributer?.length,
            active: data?.active_users,
            inactive: data?.distributer?.length - data?.active_users,
          });
          const array = [];
          data.distributer.forEach((element) => {
            const obj = { ...element };
            obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
            obj.is_active = element.is_active ? (
              <FaUserCheck size={25} className="text-success" />
            ) : (
              <FaUserMinus size={25} className="text-error" />
            );
            obj.profile_pic = <Avatar src={element.profile_pic} alt="logo" />;
            obj.id = `MS-${element.id}`;
            obj.distributer_id = `MS-${element.distributer_id}`;
            array.push(obj);
          });
          setData(array);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "id",
        header: "User ID",
        size: 0,
        filter: false,
      },
      {
        accessorKey: "profile_pic",
        header: "Profile",
        size: 0,
        filter: false,
      },
      {
        accessorKey: "name",
        header: "Customer Name",
        size: 150,
      },
      {
        accessorKey: "father_name",
        header: "Father Name",
        size: 150,
      },
      {
        accessorKey: "phone_number",
        header: "Phone",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 150,
      },
      {
        accessorKey: "aadhar_no",
        header: "Aadhar",
        size: 150,
      },
      {
        accessorKey: "distributer_id",
        header: "Sponsor ID",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: (event) => {
    //     navigate(`/list/${row?.original?.id.split("MS-")[1]}`);
    //   },
    //   sx: {
    //     cursor: "pointer",
    //   },
    // }),
  });

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      <div className="min-h-screen">
        {/* Short details about the customers cards */}
        <div>
          <div className="flex flex-col sm:flex-row items-start sm:items-center mb-3 gap-3">
            <h1 className="text-xl sm:text-2xl font-semibold text-black">
              All Distributor
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-8">
            <UsersCountCard
              title="Total Distributor"
              count={customerData?.total || 0}
              icon={<CiUser size={24} />}
              color="from-blue-500 to-indigo-600"
              average={5}
              accentColor="bg-blue-100"
            />
            <UsersCountCard
              title="Active"
              count={customerData?.active || 0}
              icon={<FaUserCheck size={24} />}
              color="from-emerald-500 to-green-600"
              average={2.5}
              accentColor="bg-emerald-100"
            />
            <UsersCountCard
              title="Inactive"
              count={customerData?.inactive || 0}
              icon={<FaUserMinus size={24} />}
              color="from-rose-500 to-red-600"
              average={-2.1}
              accentColor="bg-rose-100"
            />
          </div>
        </div>
        {/* All Customers Table */}
        <div>
          <MaterialReactTable table={table} />
        </div>
      </div>
    </ProtectedLayout>
  );
}

export default DistributorsListPage;
