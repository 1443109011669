import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function AboutPage() {
  const navigate = useNavigate();
  const brands = [
    {
      id: 1,
      title: "Health Food",
      description:
        "Nutritious and organic food products for a healthier lifestyle.",
    },
    {
      id: 2,
      title: "Kitchen & Home care",
      description:
        "Essential products for kitchen and household cleaning needs.",
    },
    {
      id: 3,
      title: "More Products",
      description:
        "A diverse range of additional items to meet various consumer needs.",
    },
  ];
  const whyChooseUs = [
    {
      id: 1,
      title: "Quality Products",
      description:
        "Experience excellence with our meticulously crafted products. Each item is a testament to superior quality, innovative design, and unmatched performance. Choose us for products that exceed your expectations and enhance your daily life.",
    },
    {
      id: 2,
      title: "Supportive Community",
      description:
        "Join a thriving network of like-minded individuals. Our supportive community offers valuable insights, shared experiences, and mutual growth. Be part of a movement that celebrates success and supports each other's journey.",
    },
    {
      id: 3,
      title: "Mission",
      description:
        "Our mission goes beyond products – we're committed to positive change. We strive to empower individuals, promote sustainability, and create lasting impact. Partner with us to be part of a greater purpose and help shape a better future.",
    },
  ];

  return (
    <>
      <div className="relative">
        <div className="absolute bottom-[40%] left-[50%] -translate-x-[50%] -translate-y-[40%]">
          <p className="text-white text-5xl font-medium italic">About Us</p>
        </div>
        <div className="">
          <img
            src={require("../../assets/Contact.jpg")}
            className="h-80 w-full object-cover"
            alt="Contact"
          />
        </div>
      </div>

      <div className="bg-neutral-2">
        <div className="max-w-[1440px] container mx-auto px-5 sm:px-0 py-5">
          {/* Mr. Manas Maurya */}
          <div className="block md:flex items-center gap-10 px-5 md:px-0 py-5 bg-white">
            <div className="w-full md:w-[40%]">
              <img
                src={require("../../assets/manas.png")}
                className=""
                alt="read_more"
              />
            </div>
            <div className="w-full md:w-[60%] flex flex-col gap-3 text-start">
              <h3 className="text-3xl font-medium">Mr. Manas Maurya</h3>
              <p>
                I have done a lot of work in my career and we did not get
                success in all those works, then I heard about direct selling,
                we felt very good. Then in March 1998, I worked in an Indian
                direct selling company and saw that it is such a platform. Those
                who make good income in a very short time. And if anyone works
                with heart in this vision, he will never be in loss. And you
                will get respect, in a very short time you can fulfill your
                thinking in reality. Many people can enjoy a good house, luxury
                car and good lifestyle.I knew very well that it is possible to
                get everything from direct selling. And some hard work had to be
                done.
              </p>
              <p>
                Then I decided in my heart that I would bring a direct selling
                company from which a common man could make his career. There are
                many such earnings in which people are not able to get money in
                the beginning and have to work very hard in the beginning. Due
                to which people get frustrated and leave the direct selling
                company. But I built a company like that. In which if you join
                International Direct Selling and sponsor two people, then your
                income starts coming.
              </p>
              <p>
                A part from this, people should get a good quality Products. Our
                company is Manufactures very good quality Products. And provide
                it to the people.
              </p>
            </div>
          </div>
          {/* Dr. Madhu Maurya */}
          <div className="block md:flex items-center gap-10 px-5 md:px-0 py-5 bg-white">
            <div className="w-full md:w-[40%]">
              <img
                src={require("../../assets/doctor.png")}
                className="w-2/3 mx-auto"
                alt="read_more"
              />
            </div>
            <div className="w-full md:w-[60%] flex flex-col gap-3 pl-5 text-start order-first">
              <h3 className="text-3xl font-medium">I am Dr. Madhu Maurya</h3>
              <p>
                My inclination was always towards public welfare. I always
                wanted to work for the welfare of women. Which I was not able to
                do alone. After a few months, I met Manas Chandra Maurya, with
                the consent of both of us, an organization named
                <b> MS MAHILA KALAYAN SEWA SANSTHN </b>
                was formed in which information is given to make women aware and
                to avoid major diseases of women. Poor and helpless children
                should be encouraged towards education. This is my goal.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <div>
              <h2 className="text-2xl font-semibold">Our Mission</h2>
              <ul className="list-disc list-inside flex flex-col gap-1.5">
                <li className="text-neutral-5">
                  At<b> MS International</b>, we are dedicated to continuously
                  designing and modernizing our direct selling models to stay
                  ahead of the times.
                </li>
                <li className="text-neutral-5">
                  Our goal is to create a seamless and successful journey for
                  our distributors, empowering them at every step.
                </li>
                <li className="text-neutral-5">
                  We provide high-quality products and services that not only
                  meet but exceed industry standards, ensuring trust and
                  satisfaction.
                </li>
                <li className="text-neutral-5">
                  Affordability remains at the core of our offerings, making
                  them accessible while fulfilling our commitment to social
                  responsibility.
                </li>
                <li className="text-neutral-5">
                  By uplifting communities and supporting our distributors, we
                  strive to make a meaningful impact on society as a whole.
                </li>
              </ul>
            </div>
            {/* Our Vision */}
            <div>
              <h2 className="text-2xl font-semibold">Our Vision</h2>
              <ul className="list-disc list-inside flex flex-col gap-1.5">
                <li className="text-neutral-5">
                  At<b> MS International</b>, we believe in empowering
                  individuals to transform their lives. Our mission is to help
                  people reach their fullest potential, unlocking ambitions and
                  turning them into reality.
                </li>
                <li className="text-neutral-5">
                  We provide the tools and opportunities for success, ensuring
                  personal growth and financial freedom.
                </li>
                <li className="text-neutral-5">
                  With a focus on style, innovation, and excellence, we inspire
                  others to live life to the fullest. Join us in enhancing
                  lifestyles, where ambition meets opportunity, and dreams
                  become achievements.
                </li>
              </ul>
            </div>
            <div>
              <h2 className="text-2xl font-semibold">Our History</h2>
              <ul className="list-disc list-inside flex flex-col gap-1.5">
                <li className="text-neutral-5">
                  The story of <b>MS International</b> began with a vision to
                  revolutionize the direct selling industry and empower
                  individuals worldwide
                </li>
                <li className="text-neutral-5">
                  Founded with a belief in the power of entrepreneurship, we
                  started our journey by providing innovative products and
                  services that cater to the needs of both distributors and
                  consumers. Over the years, we have grown from a small-scale
                  operation to a thriving international company, expanding our
                  reach while staying true to our values of integrity, quality,
                  and trust. Our success has been built on a foundation of
                  relentless dedication to improving lives through our dynamic
                  business model and strong partnerships.
                </li>
                <li className="text-neutral-5">
                  Today, <b> MS International</b> stands as a testament to the
                  power of ambition, community, and the drive to succeed, with a
                  rich history that inspires our future endeavors.
                </li>
              </ul>
            </div>
          </div>
          {/* About Our Brands */}
          <div className="mt-10">
            <h2 className="text-3xl font-semibold text-center py-8">
              About Our Brands
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-self-center gap-6">
              {brands.map((item, index) => (
                <div
                  key={index}
                  className="shadow rounded bg-card-gradient border-2 hover:border-neutral-4 duration-500 p-8 text-center"
                >
                  <h3 className="text-xl font-semibold mb-2">{item?.title}</h3>
                  <p className="text-neutral-5">{item?.description}</p>
                </div>
              ))}
            </div>
          </div>
          {/* Why Choose Us? */}
          <div className="mt-10">
            <h2 className="text-3xl font-semibold text-center py-8">
              Why Choose Us?
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-self-center gap-6">
              {whyChooseUs.map((item, index) => (
                <div
                  key={index}
                  className="shadow rounded bg-card-gradient border-2 hover:border-neutral-4 duration-500 p-8 text-center"
                >
                  <h3 className="text-xl font-semibold mb-2">{item?.title}</h3>
                  <p className="text-neutral-5">{item?.description}</p>
                </div>
              ))}
            </div>
          </div>
          {/* Join Our Team */}
          <div className="my-10 text-center flex flex-col justify-center gap-2 py-16 bg-card-gradient shadow-lg rounded-md border-2 border-neutral-4">
            <h2 className="text-3xl font-semibold">Join Our Team</h2>
            <p className="text-neutral-5 italic">
              <b>
                Build Your Dreams, Expand Your Network, and Achieve Financial
                Freedom Today!
              </b>
            </p>
            <div className="inline-block">
              <Button
                onClick={() => navigate("/signup")}
                variant="contained"
                color="secondary"
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
