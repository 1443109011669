import React from "react";
import Sidebar from "../Sidebar";

const ProtectedLayout = ({ children }) => {
  return (
    <div className="flex">
      <div className="w-72 hidden sticky top-0 sm:flex bg-neutral-3 bg-opacity-95 h-screen overflow-y-auto">
        <Sidebar />
      </div>
      <div className="w-full">
        <div className="p-5 sm:p-3 bg-neutral-2 min-h-[calc(100vh-64px)]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ProtectedLayout;
