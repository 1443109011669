import React, { useEffect, useMemo, useState } from "react";
import ProtectedLayout from "../../components/layouts/ProtectedLayout";
import AddOrder from "../../components/pop/AddOrder";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import BreadCrumbs from "../../components/BreadCrumbs";
import * as api from "../../utils/apiClient";
import Loading from "../../components/Loading";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import MakeDistributor from "../../components/pop/MakeDistributor";

export function UserDetailsPage() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [addOrderIsOpen, setAddOrderIsOpen] = useState(false);
  const [makeDistributorIsOpen, setMakeDistributorIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  // TODO: Get All Order and Add Order adding pending...
  const getAllUserOrders = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.getAllOrders(id);
      if (data) {
        const array = [];
        data?.orders?.forEach((element) => {
          const obj = { ...element };
          obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
          obj.user_id = `MS-${element.user_id}`;
          obj.total_amount = `₹${element.total_amount}`;
          array.push(obj);
        });
        setOrders(array);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const getAUser = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAUser(id);
        if (data) {
          setIsLoading(false);
          setData(data.user);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getAllUserOrders();
    getAUser();
  }, [id]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "user_id",
        header: "User ID",
        size: 150,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 50,
      },
      {
        accessorKey: "total_amount",
        header: "Total Amount",
        size: 50,
      },
      {
        accessorKey: "total_bv",
        header: "Total BV",
        size: 50,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "order_code",
        header: "Order Code",
        size: 150,
        filter: false,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    orders, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // TODO: Ask question for this (ki one order par click karne ke baad product details ka popup dikhayega or page open hoga kya ?)
    //  muiTableBodyRowProps: ({ row }) => ({
    //    onClick: (event) => {
    //      navigate(`/products/${row.original.id}`);
    //    },
    //    sx: {
    //      cursor: "pointer",
    //    },
    //  }),
  });

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      {addOrderIsOpen && (
        <AddOrder
          userId={id}
          isOpen={addOrderIsOpen}
          handleClose={() => {
            setAddOrderIsOpen(false);
            getAllUserOrders();
          }}
        />
      )}
      {makeDistributorIsOpen && (
        <MakeDistributor
          isOpen={makeDistributorIsOpen}
          userId={id}
          handleClose={() => setMakeDistributorIsOpen(false)}
        />
      )}
      {/* Back button and make distributor */}
      <div className="flex flex-col md:flex-row justify-between gap-3">
        <div>
          <BreadCrumbs />
        </div>
        <Button
          variant="contained"
          color="secondary"
          className="w-full sm:w-auto"
          onClick={() => setMakeDistributorIsOpen(true)}
        >
          MAKE DISTRIBUTOR
        </Button>
      </div>
      {/* User Details showing here */}
      <div className="md:flex justify-around mt-2">
        <ul>
          <li>
            <strong>Created At:</strong>{" "}
            {formatDateTimeWithAMAndPM(data?.created_at)}
          </li>
          <li>
            <strong>Sponsor ID:</strong> {`MS-${data?.distributer_id}`}
          </li>
          <li>
            <strong>User ID:</strong> {`MS-${data?.id}`}
          </li>
          <li>
            <strong>Name:</strong> {data?.name}
          </li>
          <li>
            <strong>Father Name:</strong> {data?.father_name}
          </li>
          <li>
            <strong>Email:</strong> {data?.email}
          </li>
          <li>
            <strong>Phone Number:</strong> {data?.phone_number}
          </li>
          <li>
            <strong>DOB:</strong> {data?.dob}
          </li>
          <li>
            <strong>Status:</strong> {data?.is_active ? "Active" : "Inactive"}
          </li>
          <li>
            <strong>KYC Status:</strong> {data?.kyc_status}
          </li>
          <li>
            <strong>Gender:</strong> {data?.gender}
          </li>
          <li>
            <strong>Aadhar No:</strong> {data?.aadhar_no}
          </li>
          <li>
            <strong>Pan No:</strong> {data?.pan_no}
          </li>
          <li>
            <strong>Education Qualification:</strong>
            {data?.education_qualification}
          </li>
          <li>
            <strong>Professional Qualification: </strong>
            {data?.professional_qualification}
          </li>
          <li>
            <strong>Work Experience:</strong> {data?.work_experience}
          </li>
          <li>
            <strong>Religion:</strong> {data?.religion}
          </li>
          <li>
            <strong>Cast:</strong> {data?.caste}
          </li>
        </ul>
        <div>
          <div className="h-40 w-40 border-2">
            <img
              src={data?.profile_pic ? data?.profile_pic : "/profile.png"}
              alt="Profile_picture"
              className="h-full w-full object-cover object-center rounded"
            />
          </div>
          <h2 className="text-2xl font-bold text-center mb-4">{data?.name}</h2>
        </div>
      </div>
      {/* Order history showing here */}
      <div>
        {orders?.length > 0 && (
          <>
            <div className="flex flex-col md:flex-row justify-between gap-3 pb-3">
              <div>
                <h6 className="text-2xl font-semibold">Order history</h6>
              </div>
              <Button
                variant="contained"
                color="secondary"
                className="w-full sm:w-auto"
                onClick={() => setAddOrderIsOpen(true)}
              >
                Add Order
              </Button>
            </div>
            <div>
              <MaterialReactTable table={table} />
            </div>
          </>
        )}
      </div>
    </ProtectedLayout>
  );
}
