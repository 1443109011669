import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdHome } from "react-icons/io";

const BreadCrumbs = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const pathSegments = pathname.split("/").filter((segment) => segment);

  const breadcrumbItems = pathSegments.map((segment, index) => {
    const url = "/" + pathSegments.slice(0, index + 1).join("/");
    const label = segment.charAt(0).toUpperCase() + segment.slice(1);

    return {
      label,
      url,
    };
  });

  return (
    <div className="flex items-center gap-1 text-neutral-4">
      <Link to="/">
        <p>
          <IoMdHome size={25} />
        </p>
      </Link>
      {breadcrumbItems.map((item, index) => (
        <div className="flex justify-center items-center gap-1" key={index}>
          {index > 0 && <span>&gt;</span>}
          <Link to={item.url}>
            <p className="font-semibold text-lg">{item.label}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BreadCrumbs;
