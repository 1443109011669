import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
// import { calculateDiscountPrice } from "../../utils/PriceCalculate";

function ProductCard({ data }) {
  const { product_id, name, thumbnail, price, margin, discount } = data;
  const navigate = useNavigate();

  return (
    <div className="shadow rounded p-1 bg-white border-2 relative">
      {/* <div className="absolute top-2 right-2 font-semibold">
        <div className="bg-success p-3.5 rounded-full relative">
          <p className="text-white text-xs absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            {discount > 0 && `${discount}%`}
          </p>
        </div>
      </div> */}
      <img
        src={thumbnail}
        alt={name}
        className="w-full h-48 rounded object-cover"
      />
      <div className="p-2">
        <h3 className="text-lg font-semibold">{name}</h3>
        {/* <p className="text-secondary font-semibold text-lg text-opacity-90">{`₹${calculateDiscountPrice(
          price,
          discount
        )}`}</p> */}
        <div>
          <table className="inline-flex border rounded">
            <ul>
              <li className="border py-2 px-4">MRP</li>
              <li className="border py-2 px-4">D P</li>
              <li className="border py-2 px-4">B V</li>
            </ul>
            <ul>
              <li className="border py-2 px-4">{discount}</li>
              <li className="border py-2 px-4">{price}</li>
              <li className="border py-2 px-4">{margin}</li>
            </ul>
          </table>
        </div>
      </div>
      {/* Buttons */}
      <div className="flex justify-end gap-5 p-2 items-center">
        <Button
          onClick={() => navigate(`/products/${product_id}`)}
          color="secondary"
          variant="outlined"
        >
          Quick View
        </Button>
      </div>
    </div>
  );
}

export default ProductCard;
