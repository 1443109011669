import React from "react";
import ResetForm from "../../components/forms/ResetForm";

const ResetPasswordPage = () => {
  return (
    <>
      <ResetForm />
    </>
  );
};

export default ResetPasswordPage;
