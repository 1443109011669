import React from 'react';

function DashboardCard({ color, title, data, total }) {
  return (
    <div>
      <div className={`${color} text-white p-6 rounded-lg`}>
        <h2 className="text-3xl font-bold">{total}</h2>
        <p className="text-xl">{title}</p>
        <small>{data}</small>
      </div>
    </div>
  );
}

export default DashboardCard;
