import React, { useEffect, useState } from "react";
import ProductForm from "../../../components/forms/ProductForm";
import ProtectedLayout from "../../../components/layouts/ProtectedLayout";
import ProductCreatingSteps from "../../../components/ui/ProductCreatingSteps";
import { useNavigate } from "react-router-dom";

function CreateProductPage() {
  const [productId, setProductId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      navigate(`/products/images/${productId}`);
    }
  }, [navigate, productId]);

  return (
    <ProtectedLayout>
      <div className="">
        <ProductCreatingSteps step={0} />
        <div className="w-full sm:w-[70%] mx-auto py-10">
          {/* Create Product Form */}
          <ProductForm productId={(id) => setProductId(id)} />
        </div>
      </div>
    </ProtectedLayout>
  );
}

export default CreateProductPage;
