import React, { useContext, useState } from "react";
import ProtectedLayout from "../../components/layouts/ProtectedLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import * as api from "../../utils/apiClient";
import Loading from "../../components/Loading";

function MyAccountPage() {
  const [isPersonalEditing, setIsPersonalEditing] = useState(false);
  const [isNomineeEditing, setIsNomineeEditing] = useState(false);
  const [isBankEditing, setIsBankEditing] = useState(false);
  const [isBillingEditing, setIsBillingEditing] = useState(false);
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const { profile } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const personalFormik = useFormik({
    initialValues: profile,
    validationSchema: Yup.object({
      // Add validation rules here
    }),
  });
  const handlePersonalForm = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone_number", values.phone_number);
    formData.append("father_name", values.father_name);
    formData.append("pan_no", values.pan_no);
    formData.append("gender", values.gender);
    formData.append("religion", values.religion);
    formData.append(
      "professional_qualification",
      values.professional_qualification
    );
    formData.append("education_qualification", values.education_qualification);
    formData.append("work_experience", values.work_experience);
    formData.append("current_job", values.current_job);
    formData.append("dob", values.dob);
    formData.append("caste", values.caste);
    formData.append("aadhar_no", values.aadhar_no);

    try {
      const { data } = await api.personalDetailUpdate(profile.id, formData);
      if (data) {
        toast.success(data.message);
        setIsPersonalEditing(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  const nomineeFormik = useFormik({
    initialValues: {
      name: "Jane Doe",
      dob: "1995-05-05",
      address: "123 Main St, City, Country",
    },
    validationSchema: Yup.object({
      // Add validation rules here
    }),
    onSubmit: (values) => {
      console.log("Nominee form submitted:", values);
      // setIsNomineeEditing(false)
    },
  });

  const bankFormik = useFormik({
    // initialValues: {
    //   account_holder_name: "",
    //   bank_branch: "",
    //   bank_ifsc: "",
    //   bank_acc: "",
    //   bank_name: "",
    // },
    initialValues: profile,
    validationSchema: Yup.object({
      // Add validation rules here
    }),
  });
  const handleBankDetailsForm = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await api.addBankDetails({
        user_id: profile.id,
        ...values,
      });
      if (data) {
        toast.success(data.message);
        setIsBankEditing(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };
  const billingFormik = useFormik({
    // initialValues: {
    //   village: "",
    //   post: "",
    //   thana: "",
    //   tehsil: "",
    //   district: "",
    //   state: "",
    //   pin_code: "",
    //   city: "",
    //   country: "",
    // },
    initialValues: profile,
    validationSchema: Yup.object({
      // Add validation rules here
    }),
    onSubmit: (values) => {
      console.log("Billing address form submitted:", values);
      // setIsBillingEditing(false)
    },
  });
  const handleBillingForm = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await api.addAddressDetails({
        user_id: profile.id,
        ...values,
      });
      if (data) {
        toast.success(data.message);
        setIsBillingEditing(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };
  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string().required("New Password is required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Re-type New Password is required"),
    }),
    onSubmit: (values) => {
      console.log("Password change form submitted:", values);
      // setIsPasswordEditing(false)
    },
  });

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      <Box>
        <div className="flex justify-between items-center pb-3">
          <Typography variant="h5" className="mb-4">
            Account Details
          </Typography>
          <Box className="">
            {isPersonalEditing ? (
              <Button
                onClick={() => {
                  handlePersonalForm(personalFormik.values);
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsPersonalEditing(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </div>

        {/* Personal Details Form */}
        <form onSubmit={personalFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="id"
                name="id"
                label="User ID"
                size="small"
                value={`MS-${profile && profile?.id}`}
                focused
                // onChange={personalFormik.handleChange}
                // disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="id"
                name="id"
                label="Sponsor ID"
                size="small"
                defaultValue={""}
                value={`MS-${profile && profile?.distributer_id}`}
                // onChange={personalFormik.handleChange}
                // disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                size="small"
                value={personalFormik.values.name}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="phone_number"
                name="phone_number"
                label="Phone"
                size="small"
                value={personalFormik.values.phone_number}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                size="small"
                value={personalFormik.values.email}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="father_name"
                name="father_name"
                size="small"
                label="Father’s Name"
                value={personalFormik.values.father_name}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="aadhar_no"
                name="aadhar_no"
                size="small"
                label="Aadhar Number"
                value={personalFormik.values.aadhar_no}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="pan_no"
                name="pan_no"
                label="PAN Number"
                size="small"
                value={personalFormik.values.pan_no}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="dob"
                name="dob"
                label="Date of Birth"
                type="date"
                size="small"
                value={personalFormik.values.dob}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="caste"
                name="caste"
                label="Caste"
                size="small"
                value={personalFormik.values.caste}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={!isPersonalEditing}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  size="small"
                  value={personalFormik.values.gender}
                  onChange={personalFormik.handleChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="education_qualification"
                name="education_qualification"
                label="Education Qualification"
                size="small"
                value={personalFormik.values.education_qualification}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="professional_qualification"
                name="professional_qualification"
                label="Professional Qualification"
                size="small"
                value={personalFormik.values.professional_qualification}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="religion"
                name="religion"
                label="Religion"
                size="small"
                value={personalFormik.values.religion}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="work_experience"
                name="work_experience"
                label="Work Experience"
                size="small"
                value={personalFormik.values.work_experience}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="current_job"
                name="current_job"
                label="Current Job"
                size="small"
                value={personalFormik.values.current_job}
                onChange={personalFormik.handleChange}
                disabled={!isPersonalEditing}
              />
            </Grid>
          </Grid>
        </form>
        <hr className="my-8" />

        {/* Nominee Details Form */}
        <div className="flex justify-between items-center pb-3">
          <Typography variant="h5" className="mb-4">
            Nominee Details
          </Typography>
          <Box className="mt-4">
            {isNomineeEditing ? (
              <Button
                onClick={() => setIsNomineeEditing(false)}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsNomineeEditing(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </div>
        <form onSubmit={nomineeFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                size="small"
                value={nomineeFormik.values.name}
                onChange={nomineeFormik.handleChange}
                disabled={!isNomineeEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="dob"
                name="dob"
                label="Date of Birth"
                type="date"
                size="small"
                value={nomineeFormik.values.dob}
                onChange={nomineeFormik.handleChange}
                disabled={!isNomineeEditing}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="address"
                name="address"
                label="Address"
                size="small"
                value={nomineeFormik.values.address}
                onChange={nomineeFormik.handleChange}
                disabled={!isNomineeEditing}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </form>
        <hr className="my-8" />

        {/* Bank Details Form */}
        <div className="flex justify-between items-center pb-3">
          <Typography variant="h5" className="mb-4">
            Bank Details
          </Typography>
          <Box className="mt-4">
            {isBankEditing ? (
              <Button
                onClick={() => {
                  handleBankDetailsForm(bankFormik.values);
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsBankEditing(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </div>
        <form onSubmit={bankFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="bank_name"
                name="bank_name"
                label="Bank Name"
                size="small"
                value={bankFormik.values.bank_name}
                onChange={bankFormik.handleChange}
                disabled={!isBankEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="bank_acc"
                name="bank_acc"
                label="Account Number"
                size="small"
                value={bankFormik.values.bank_acc}
                onChange={bankFormik.handleChange}
                disabled={!isBankEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="bank_ifsc"
                name="bank_ifsc"
                label="IFSC Code"
                size="small"
                value={bankFormik.values.bank_ifsc}
                onChange={bankFormik.handleChange}
                disabled={!isBankEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="bank_branch"
                name="bank_branch"
                label="Bank Branch"
                size="small"
                value={bankFormik.values.bank_branch}
                onChange={bankFormik.handleChange}
                disabled={!isBankEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="account_holder_name"
                name="account_holder_name"
                label="Account Holder Name"
                size="small"
                value={bankFormik.values.account_holder_name}
                onChange={bankFormik.handleChange}
                disabled={!isBankEditing}
              />
            </Grid>
          </Grid>
        </form>

        <hr className="my-8" />
        {/* Billing Address Form */}
        <div className="flex justify-between items-center pb-3">
          <Typography variant="h5" className="mb-4">
            Billing Address
          </Typography>
          <Box className="mt-4">
            {isBillingEditing ? (
              <Button
                onClick={() => handleBillingForm(billingFormik.values)}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsBillingEditing(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </div>
        <form onSubmit={billingFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="state"
                name="state"
                label="State"
                size="small"
                value={billingFormik.values.state}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="pin_code"
                name="pin_code"
                label="Pin code"
                size="small"
                value={billingFormik.values.pin_code}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="district"
                name="district"
                label="District"
                size="small"
                value={billingFormik.values.district}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="post"
                name="post"
                label="Post Office"
                size="small"
                value={billingFormik.values.post}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="thana"
                name="thana"
                label="Thana"
                size="small"
                value={billingFormik.values.thana}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                size="small"
                value={billingFormik.values.city}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="country"
                name="country"
                label="Country"
                size="small"
                value={billingFormik.values.country}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="tehsil"
                name="tehsil"
                label="Tehsil"
                size="small"
                value={billingFormik.values.tehsil}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="village"
                name="village"
                label="Village"
                size="small"
                value={billingFormik.values.village}
                onChange={billingFormik.handleChange}
                disabled={!isBillingEditing}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </form>
        <hr className="my-8" />

        {/* Change Password Form */}
        <div className="flex justify-between items-center pb-3">
          <Typography variant="h5" className="mb-4">
            Change Password
          </Typography>
          <Box className="mt-4">
            {isPasswordEditing ? (
              <Button
                onClick={() => setIsPasswordEditing(false)}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsPasswordEditing(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </div>
        <form onSubmit={passwordFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="oldPassword"
                name="oldPassword"
                label="Old Password"
                type="password"
                size="small"
                value={passwordFormik.values.oldPassword}
                onChange={passwordFormik.handleChange}
                disabled={!isPasswordEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="newPassword"
                name="newPassword"
                label="New Password"
                type="password"
                size="small"
                value={passwordFormik.values.newPassword}
                onChange={passwordFormik.handleChange}
                disabled={!isPasswordEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="confirmNewPassword"
                name="confirmNewPassword"
                label="Re-type New Password"
                type="password"
                size="small"
                value={passwordFormik.values.confirmNewPassword}
                onChange={passwordFormik.handleChange}
                disabled={!isPasswordEditing}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </ProtectedLayout>
  );
}

export default MyAccountPage;
