import React from "react";
import TopHeroSection from "../../components/sections/TopHeroSection";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div>
      <TopHeroSection title="Terms and Conditions" />
      <div className="max-w-[1440px] container mx-auto px-5 sm:px-0 relative bg-neutral-2">
        <div className="max-w-5xl mx-auto border p-5 sm:p-10 bg-white rounded -translate-y-28">
          <p>
            Welcome to <b>MS International</b>. By accessing or using our
            website and services {` (`}
            including
            {""}
            <Link
              target="_blank"
              to={"https://www.msinternational.org.in/"}
              className="text-secondary px-1"
            >
              www.msinternational.org.in
            </Link>
            , its related websites, and mobile apps{`).`}, you agree to comply
            with and be bound by the following terms and conditions. Please read
            these terms carefully before using our services. If you do not agree
            with these terms, you should discontinue the use of our services.
          </p>
          <br />
          <div>
            <ol className="list-decimal px-5">
              <li>
                <h5 className="font-semibold text-lg">
                  Information Collection:
                </h5>
                <ul>
                  <li>
                    <ul className="list-inside list-disc flex flex-col gap-2">
                      <li>
                        We collect personal information such as your name, email
                        address, phone number, and other relevant data when you
                        register an account with us or place an order. This
                        information is required for providing our services, such
                        as product orders, managing your membership, and
                        accessing exclusive features on our websites{` (`}
                        including
                        {""}
                        <Link
                          target="_blank"
                          to={"https://www.msinternational.org.in/"}
                          className="text-secondary px-1"
                        >
                          www.msinternational.org.in
                        </Link>
                        , its related websites, and mobile apps{`).`}
                      </li>
                      <li>
                        Additionally, when you visit our website, we may collect
                        non-personal information like your IP address, browser
                        type, operating system, and the pages you visit. This
                        data helps us enhance user experience and is not linked
                        back to your personal identity.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Acceptance of Terms:</h5>
                <ul>
                  <li>
                    By using our website and services, you acknowledge that you
                    have read, understood, and agree to abide by these Terms and
                    Conditions, as well as our Privacy Policy. MS International
                    reserves the right to modify these terms at any time without
                    prior notice. Your continued use of the website after any
                    changes will constitute your acceptance of such
                    modifications.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Eligibility:</h5>
                <ul>
                  <li>
                    To use the services of MS International, you must be at
                    least 18 years old or of legal age in your jurisdiction,
                    whichever is higher. By registering for an account or
                    participating in our network marketing program, you confirm
                    that you meet these eligibility requirements.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Account Registration and Responsibilities:
                </h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    When registering for an account with <b>MS International</b>
                    , you agree to provide accurate, up-to-date, and complete
                    information. You are responsible for maintaining the
                    confidentiality of your account details, including your
                    password, and for any activities that occur under your
                    account.
                  </li>
                  <li>
                    <b>MS International</b>
                    reserves the right to terminate accounts, refuse service, or
                    cancel orders at its discretion, particularly if we believe
                    that the account violates these Terms and Conditions or is
                    involved in fraudulent or harmful activity.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Product and Service Information:
                </h5>
                <ul>
                  <li>
                    We strive to provide accurate information regarding our
                    products and services. However, we do not warrant that the
                    descriptions, pricing, or other content available on our
                    site is free from errors. We reserve the right to correct
                    any errors or inaccuracies, including after an order has
                    been submitted, and to change or update information without
                    prior notice.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Orders and Payments:</h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    All orders placed through MS International are subject to
                    acceptance by us. We reserve the right to refuse or cancel
                    any order at any time, including those made using incorrect
                    pricing or other errors. In case of cancellations, any
                    payments made will be refunded.
                  </li>
                  <li>
                    You agree to pay all fees associated with your purchases in
                    a timely manner. MS International reserves the right to
                    charge interest on overdue payments.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Network Marketing and Distributor Obligations:
                </h5>
                <ul className="list-inside list-disc flex flex-col gap-2">
                  <li>
                    As a member or distributor of MS International, you agree to
                    comply with all applicable laws and regulations. You are
                    responsible for ensuring that your activities in promoting
                    and selling MS International products are ethical and align
                    with our guidelines.
                  </li>
                  <li>
                    Distributors are not employees of MS International but
                    operate as independent contractors. You agree to adhere to
                    the policies and procedures outlined in our Distributor
                    Agreement, and failure to do so may result in termination of
                    your distributor status.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Intellectual Property:
                </h5>
                <ul>
                  <li>
                    All content, materials, trademarks, logos, and intellectual
                    property displayed on our website or used in connection with
                    our products and services are the exclusive property of{" "}
                    <b>MS International</b>. You are not permitted to use,
                    reproduce, or distribute any content without obtaining
                    explicit written consent from <b>MS International</b>.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Limitation of Liability:
                </h5>
                <ul>
                  <li>
                    <b>MS International</b> and its affiliates, officers,
                    directors, employees, and agents shall not be liable for any
                    direct, indirect, incidental, consequential, or punitive
                    damages arising from your use of our services. We are not
                    responsible for any loss of profits, revenue, or data
                    resulting from the use or inability to use our services,
                    even if we have been advised of the possibility of such
                    damages.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Indemnification:</h5>
                <ul>
                  <li>
                    You agree to indemnify, defend, and hold harmless MS
                    International and its affiliates from any claims,
                    liabilities, damages, costs, or expenses {`(`}including
                    legal fees{`)`} arising from your use of our services, your
                    breach of these Terms and Conditions, or your violation of
                    any third-party rights.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Returns and Refunds:</h5>
                <ul>
                  <li>
                    We aim to ensure customer satisfaction with our products. In
                    case of dissatisfaction, we offer returns and refunds in
                    accordance with our return policy. Please refer to our
                    separate Return and Refund Policy for more details.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">
                  Termination of Service:
                </h5>
                <ul>
                  <li>
                    <b>MS International</b> reserves the right to terminate or
                    suspend your access to our website and services at any time,
                    without notice, for conduct that we believe violates these
                    Terms and Conditions, our policies, or is harmful to other
                    users, us, or third parties.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Governing Law:</h5>
                <ul>
                  <li>
                    These Terms and Conditions are governed by and construed in
                    accordance with the laws of Uttar Pradesh. Any disputes
                    arising from these terms will be resolved in the courts of
                    Uttar Pradesh, and you consent to the exclusive jurisdiction
                    of such courts.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Amendments:</h5>
                <ul>
                  <li>
                    We may update or modify these Terms and Conditions at any
                    time, without prior notice. It is your responsibility to
                    review these terms periodically. Your continued use of our
                    services following any changes constitutes your acceptance
                    of the updated terms.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h5 className="font-semibold text-lg">Contact Us:</h5>
                <ul>
                  <li>
                    If you have any questions or concerns regarding these Terms
                    and Conditions, please contact us at
                  </li>
                  <li>
                    <a
                      className="text-secondary"
                      href="mailto:team@msinternational.org.in"
                    >
                      team@msinternational.org.in
                    </a>
                  </li>
                </ul>
              </li>
            </ol>
            <div className="pt-1.5 italic text-end">
              <p>Thank you for your consideration.</p>
              <p>01-09-2024</p>
            </div>
          </div>
          <br />
          {/* <div>
            <span className="font-semibold text-lg">Data Privacy:&nbsp;</span>
            This privacy policy is designed specifically for{" "}
            <b>MS International</b> to ensure transparency in how you handle
            customer data while complying with best practices in network
            marketing.
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Terms;
