import React from "react";

function TopHeroSection({ title = "Title" }) {
  return (
    <div className="relative">
      <div className="absolute bottom-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
        <p className="text-white text-5xl font-medium italic">{title}</p>
      </div>
      <div>
        <img
          src={require("../../assets/Contact.jpg")}
          className="h-80 w-full object-cover"
          alt="Contact"
        />
      </div>
    </div>
  );
}

export default TopHeroSection;
