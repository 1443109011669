import React from 'react'
import HeroSection from '../../components/sections/HeroSection'
import ShortDetailsPage from '../../components/short-details-page/ShortDetailsPage'
import AwardRecognition from '../../components/award-recogntion/AwardRecognition'
import ShortDetailsAboutCorporate from '../../components/short-details-page/ShortDetailsAboutCorporate'
// import FAQ from '../../components/FAQ'
// import Newsletter from "../../components/sections/Newsletter";

function HomePage() {
  // const faq = [
  //   {
  //     question: 'Are you satisfied with your life?',
  //     answer: 'No',
  //   },
  //   {
  //     question: 'Are you satisfied with the work you are doing?',
  //     answer: 'No',
  //   },
  //   {
  //     question:
  //       "With what you're earning today, can you afford the lifestyle you want and live comfortably?",
  //     answer: 'No',
  //   },
  //   {
  //     question:
  //       'Can you fulfill your dream of building a nice house with the money you’re earning?',
  //     answer: 'No',
  //   },
  //   {
  //     question:
  //       'Do you dream of buying a nice car with your current income? Can you achieve that?',
  //     answer: 'No',
  //   },
  // ]

  return (
    <div className='max-w-[1440px] container mx-auto'>
      <div>
        <HeroSection />
      </div>
      {/* TODO: Make (Discover Our Products) */}
      {/* https://www.amway.in/ */}
      <div>
        <ShortDetailsPage />
      </div>
      <div>
        <ShortDetailsAboutCorporate />
      </div>
      <div>
        <AwardRecognition />
      </div>
      <div className='py-10'>
        <div className='pb-5 '>
          <h1 className='text-3xl font-semibold text-center'>
            Benefits Of Joining MS International
          </h1>
          <p className='text-lg pt-1 italic'>
            Before telling you the future, I will ask you some questions.
          </p>
        </div>
        {/* {faq?.map((item, index) => {
          return (
            <FAQ key={index} question={item.question} answer={item.answer} />
          );
        })} */}

        <div className='mx-auto space-y-6  bg-white'>
          <div className='space-y-4'>
            <p className='p-3 bg-red-100 rounded text-red-700'>
              1. Are you satisfied with your life?{' '}
              <span className='font-semibold'>No</span>
            </p>

            <p className='p-3 bg-red-100 rounded text-red-700'>
              2. Are you satisfied with the work you are doing?{' '}
              <span className='font-semibold'>No</span>
            </p>

            <p className='p-3 bg-red-100 rounded text-red-700'>
              3. What you are earning today, can you afford it for yourself and
              your lifestyle? Can have a good life?{' '}
              <span className='font-semibold'>No</span>
            </p>

            <p className='p-3 bg-red-100 rounded text-red-700'>
              4. Are you fulfilling your dream of building a good house with the
              money you are earning? <span className='font-semibold'>No</span>
            </p>

            <p className='p-3 bg-red-100 rounded text-red-700'>
              5. Do you dream of buying a good car with the money you are
              earning? <span className='font-semibold'>Can't complete it</span>
            </p>
          </div>

          <div className='mt-8 p-4 bg-blue-100 rounded'>
            <h3 className='text-xl font-semibold text-blue-800 mb-3'>
              The Solution:
            </h3>
            <p className='text-blue-700'>
              Direct Selling International - M.S. INTERNATIONAL NET WORKING PVT.
              LTD.
            </p>
          </div>

          <div className='mt-8 space-y-4'>
            <h3 className='text-xl font-semibold text-gray-800'>
              Historical Perspective on Living Expenses
            </h3>

            <div className='grid grid-cols-2 gap-4'>
              <p className='p-2 bg-gray-100 rounded'>
                <span className='font-semibold'>1960:</span> ₹10 per month
              </p>
              <p className='p-2 bg-gray-100 rounded'>
                <span className='font-semibold'>1980:</span> ₹100 per month
              </p>
              <p className='p-2 bg-gray-100 rounded'>
                <span className='font-semibold'>2000:</span> ₹20,000 per month
              </p>
              <p className='p-2 bg-gray-100 rounded'>
                <span className='font-semibold'>2024:</span> ₹50,000 per month
              </p>
              <p className='p-2 bg-yellow-100 rounded'>
                <span className='font-semibold'>2040 (Projected):</span>{' '}
                ₹100,000 per month
              </p>
            </div>
          </div>

          <div className='mt-6 p-4 bg-green-100 rounded'>
            <p className='text-green-700 font-medium'>
              Can we earn ₹1 lakh per month from our current work?{' '}
              <span className='font-bold'>No</span>
            </p>
            <p className='text-green-700 font-medium mt-2'>
              Solution: We should resort to network marketing. Direct Selling
              International - M.S. INTERNATIONAL NET WORKING PVT. LTD.
            </p>
          </div>
        </div>
      </div>
      <div>{/* <Newsletter /> */}</div>
    </div>
  )
}

export default HomePage
