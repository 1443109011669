import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as api from "../../utils/apiClient";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import Confirmation from "./Confirmation";

function MakeDistributor({ isOpen, handleClose, userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [sponsorDetail, setSponsorDetail] = useState({});
  const [formData, setFormData] = useState({
    role: "distributer",
    user_id: userId * 1,
    sponsor_id: "",
    position: "left",
  });
  const navigate = useNavigate();

  const makeDistributor = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.makeDistributer(formData);
      if (data) {
        handleClose(false);
        navigate("/users");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    // const getCurrentSponsorDetailPosition = async () => {
    //   setIsLoading(true);
    //   try {
    //     const { data } = await api.sponsorLeftRightPositionCheck(
    //       formData.sponsor_id
    //     );
    //     console.log("Data", data);
    //     if (data) {
    //       setSponsorDetail(data.user);
    //     }
    //     setIsLoading(false);
    //   } catch (error) {
    //     setIsLoading(false);
    //     console.log(error);
    //     setSponsorDetail({});
    //   }
    // };

    const getSponsorDetail = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.sponsorDetails(formData.sponsor_id);
        if (data) {
          setSponsorDetail(data.user);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        setSponsorDetail({});
      }
    };
    getSponsorDetail();
  }, [formData]);
  return (
    <>
      {isLoading && <Loading />}
      <Confirmation
        isOpen={confirmation}
        confirmToCallFunction={() => makeDistributor()}
        handleClose={() => setConfirmation(false)}
        title="Are you sure you want to make a distributor?"
        massage="Are you sure you want to make a distributor? This action will enable
            you to join our network and start selling our products."
      />
      <React.Fragment>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Make Distributor
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <div className="flex flex-col sm:flex-row justify-center gap-7">
              <form className="flex flex-col gap-5 w-full">
                <div>
                  <TextField
                    id="referralId"
                    label="Sponsor ID"
                    variant="outlined"
                    fullWidth={true}
                    value={formData.sponsor_id}
                    size="small"
                    color="info"
                    required
                    name="sponsor_id"
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        sponsor_id: event.target.value * 1,
                      })
                    }
                  />
                </div>
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="position-label">Position</InputLabel>
                    <Select
                      size={"small"}
                      color="info"
                      labelId="position-label"
                      name="position"
                      label="Position"
                      value={formData.position}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          position: event.target.value,
                        })
                      }
                    >
                      <MenuItem value="left">Org 1</MenuItem>
                      <MenuItem value="right">Org 2</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form>
              <div>
                <div>
                  {/* Main Admin */}
                  <div className="h-32 w-32 m-auto">
                    <div className="w-20 h-14 m-auto border-2 rounded border-black relative flex justify-center items-center">
                      <h3 className="text-sm font-semibold capitalize">
                        {sponsorDetail?.name}
                      </h3>
                      <hr className="w-3.5 border border-black absolute -bottom-2 rotate-90 left-1/2 -translate-x-1/2" />
                    </div>
                    <div>
                      <div className="h-0.5 bg-black w-[80%] m-auto my-[13px] flex justify-between">
                        <div className="h-5 w-0.5 bg-black relative">
                          <div
                            className={`${
                              formData.position === "left"
                                ? "border-secondary"
                                : "border-black"
                            }  w-12 h-8 border-2 rounded absolute -left-[25px] -bottom-[150%] flex justify-center items-center`}
                          >
                            <p className="uppercase text-sm font-semibold text-neutral-4 duration-300">
                              {formData.position === "left" && "YOU"}
                            </p>
                          </div>
                        </div>
                        <div className="h-5 w-0.5 bg-black relative">
                          <div
                            className={`${
                              formData.position === "right"
                                ? "border-secondary"
                                : "border-black"
                            }  w-12 h-8 border-2 rounded absolute -right-[25px] -bottom-[150%] flex justify-center items-center`}
                          >
                            <p className="uppercase text-sm font-semibold text-neutral-4 duration-300">
                              {formData.position === "right" && "YOU"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmation(true)}
              disabled={!formData.sponsor_id + ""?.length > 0}
              variant="contained"
              color="secondary"
              disableElevation
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}

export default MakeDistributor;
