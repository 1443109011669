import React from "react";
import { TextField, Button } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as api from "../../utils/apiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

const ForgotPasswordSchema = Yup.object().shape({
  id: Yup.string().required("User Id is required"),
});

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const sendEmail = async (values) => {
    setIsLoading(true);
    const payload = {
      id: values.id * 1,
      reset_password_ui_url: `${api.siteBaseURL}/reset_password`,
    };
    try {
      const res = await api.forgotPassword(payload);
      toast.success(res.data.message);
      setIsLoading(false);
      navigate("/login");
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message) {
        toast(err?.response?.data?.message);
      }
      setIsLoading(false);
    }
  };
  const handleSubmit = (values, { setSubmitting }) => {
    sendEmail(values);
    setSubmitting(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="flex items-center justify-center h-screen bg-neutral-2">
        <div className="mx-5 border-2 border-neutral-3 bg-white p-8 rounded w-full sm:w-1/4 text-center">
          <img
            src={require("../../assets/new_logo.png")}
            alt="logo"
            className="h-24 w-24 mx-auto rounded-full"
          />
          <h2 className="text-center text-2xl">Forgot Password</h2>
          <p className="text-base text-center text-gray-500 mb-5">
            Enter your username to reset password
          </p>
          <Formik
            initialValues={{ id: "" }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, values, isValid }) => (
              <Form>
                <Field
                  as={TextField}
                  name="id"
                  label="User Id*"
                  variant="outlined"
                  fullWidth
                  size={"small"}
                  margin="normal"
                  error={touched.id && errors.id}
                  helperText={touched.id && errors.id}
                />
                <div className="mt-4">
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={!values.id || !isValid || isSubmitting}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
