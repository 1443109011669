import React, { useState } from "react";
import {
  FaChevronRight,
  FaChevronDown,
  FaHome,
  FaCog,
  FaUser,
  FaTachometerAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TreeItem = ({ label, children, icon, onClick, depth = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    if (children) {
      setIsOpen(!isOpen);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={`${depth === 0 ? "" : "ml-3"}`}>
      <div
        className={`flex items-center cursor-pointer p-2 rounded
          hover:bg-gray-100 active:bg-gray-200 
          ${depth === 0 ? "mb-1" : "my-1"}`}
        onClick={handleClick}
      >
        <div className="flex items-center min-w-[20px]">
          {children &&
            (isOpen ? (
              <FaChevronDown className="w-3 h-3 text-gray-600" />
            ) : (
              <FaChevronRight className="w-3 h-3 text-gray-600" />
            ))}
        </div>

        <div
          className={`flex items-center gap-2 ${children ? "ml-1" : "ml-4"}`}
        >
          {icon && <div className="text-gray-600">{icon}</div>}
          <span className={`text-sm ${depth === 0 ? "font-semibold" : ""}`}>
            {label}
          </span>
        </div>
      </div>

      {isOpen && children && (
        <div className="border-l border-gray-200 ml-3 pl-2">{children}</div>
      )}
    </div>
  );
};

const CustomTreeView = ({ root, onNavigate }) => {
  const navigate = useNavigate();
  // Example data with FaIcons
  // const sampleData = {
  //   id: "root",
  //   name: "Main Menu",
  //   icon: <FaHome className="w-4 h-4" />,
  //   children: [
  //     {
  //       id: "1",
  //       name: "Dashboard",
  //       icon: <FaTachometerAlt className="w-4 h-4" />,
  //       path: "/dashboard",
  //     },
  //     {
  //       id: "2",
  //       name: "Settings",
  //       icon: <FaCog className="w-4 h-4" />,
  //       children: [
  //         {
  //           id: "2.1",
  //           name: "Profile",
  //           icon: <FaUser className="w-4 h-4" />,
  //           path: "/settings/profile",
  //         },
  //       ],
  //     },
  //   ],
  // };

  const data = root || [];

  const renderTree = (node, depth = 0) => (
    <TreeItem
      key={node.id}
      label={node.name}
      icon={node.icon}
      depth={depth}
      // onClick={() => {
      //   if (node.path) onNavigate?.(node.path);
      // }}
      onClick={() => {
        if (node.path) {
          navigate(node.path);
        }
        //  else if (node.children) {
        //   setIsOpen(!isOpen);
        // }
      }}
    >
      {node.children?.map((child) => renderTree(child, depth + 1))}
    </TreeItem>
  );

  return (
    <div className="w-64 p-3 bg-white shadow-sm rounded-lg">
      {renderTree(data)}
    </div>
  );
};

export default CustomTreeView;
