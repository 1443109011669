import React from "react";
import { TextField, Button, Link } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as api from "../../utils/apiClient";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading";

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ResetPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const handleForm = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await api.resetPassword(params.token, {
        password: values.newPassword,
      });

      toast.success(data.message);
      setIsLoading(false);
      navigate("/login");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
    setIsLoading(false);
  };
  const handleSubmit = (values, { setSubmitting }) => {
    handleForm(values);
    setSubmitting(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="flex justify-center items-center h-screen bg-neutral-2">
        <div className="mx-5 border-2 border-neutral-3 bg-white p-8 rounded w-full sm:w-1/4 text-center">
          <img
            src={require("../../assets/new_logo.png")}
            alt="logo"
            className="h-24 w-24 mx-auto rounded-full"
          />
          <h2 className="text-center text-2xl">Reset Password</h2>
          <h3 className=" text-center text-gray-400 mb-6">
            if your token is expired?
            <span className="ml-1.5">
              <Link
                href="/forgot_password"
                className="hover:text-primary font-medium duration-300"
                underline="hover"
              >
                click here
              </Link>
            </span>
          </h3>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, values, isValid }) => (
              <Form>
                <Field
                  as={TextField}
                  name="newPassword"
                  label="New Password*"
                  type="password"
                  variant="outlined"
                  fullWidth
                  size={"small"}
                  margin="normal"
                  error={touched.newPassword && errors.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                />
                <Field
                  as={TextField}
                  name="confirmPassword"
                  label="Confirm Password*"
                  type="password"
                  variant="outlined"
                  fullWidth
                  size={"small"}
                  margin="normal"
                  error={touched.confirmPassword && errors.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <div className="mt-3">
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
