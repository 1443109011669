import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const AdminPrivateRoutes = () => {
  const { profile } = useContext(AuthContext);

  return profile?.role === "admin" ? <Outlet /> : <Navigate to="/login" />;
};

export default AdminPrivateRoutes;
