import React, { useContext, useEffect, useMemo, useState } from "react";
import ProtectedLayout from "../../components/layouts/ProtectedLayout";
import { UsersCountCard } from "../../components/card/UsersCountCard";
import { FaUserCheck, FaUserMinus } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as api from "../../utils/apiClient";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import Loading from "../../components/Loading";
import { AuthContext } from "../../context/AuthContext";

function UsersPage() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [data, setData] = useState([]);
  const { profile } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getAllUsers = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllUsers("customer");
        if (data) {
          const array = [];
          data.users.forEach((element) => {
            if (!element.is_active) {
              setCustomerData({
                ...customerData,
                inactive: customerData?.inactive || 0 + 1,
              });
            }
            setCustomerData({
              ...customerData,
              total: data?.users?.length,
              active: data?.users?.length - customerData?.inactive,
              inactive: customerData?.inactive,
            });
            const obj = { ...element };
            obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
            obj.is_active = element.is_active ? (
              <FaUserCheck size={25} className="text-success" />
            ) : (
              <FaUserMinus size={25} className="text-error" />
            );
            obj.profile_pic = <Avatar src={element.profile_pic} alt="logo" />;
            obj.id = `MS-${element.id}`;
            obj.distributer_id = `MS-${element.distributer_id}`;
            array.push(obj);
          });
          setData(array);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    const getAllUsersForAdmin = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllUsersByAdmin();
        if (data) {
          const array = [];
          data.users.forEach((element) => {
            if (!element.is_active) {
              setCustomerData({
                ...customerData,
                inactive: customerData?.inactive || 0 + 1,
              });
            }
            setCustomerData({
              ...customerData,
              total: data?.users?.length,
              active: data?.users?.length - customerData?.inactive,
              inactive: customerData?.inactive,
            });
            const obj = { ...element };
            obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
            obj.is_active = element.is_active ? (
              <FaUserCheck size={25} className="text-success" />
            ) : (
              <FaUserMinus size={25} className="text-error" />
            );
            obj.profile_pic = <Avatar src={element.profile_pic} alt="logo" />;
            obj.id = `MS-${element.id}`;
            obj.distributer_id = `MS-${element.distributer_id}`;
            array.push(obj);
          });
          setData(array);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    if (localStorage.getItem("user_role") === "admin") {
      getAllUsersForAdmin();
    } else {
      getAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "id",
        header: "User ID",
        size: 0,
        filter: false,
      },
      {
        accessorKey: "profile_pic",
        header: "Profile",
        size: 0,
        filter: false,
      },
      {
        accessorKey: "name",
        header: "Customer Name",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 150,
      },
      {
        accessorKey: "gender",
        header: "Gender",
        size: 150,
      },
      {
        accessorKey: "is_active",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "distributer_id",
        header: "Sponsor ID",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/users/${row?.original?.id.split("MS-")[1]}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      <div className="min-h-screen">
        {/* Short details about the customers cards */}
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-3 gap-3">
            <h1 className="text-xl sm:text-2xl font-semibold text-black">
              All Customers
            </h1>
            <Button
              onClick={() =>
                navigate(
                  `/signup?sponsor_id=MS-${
                    Object.keys(profile).length > 0 ? profile?.id : "01"
                  }`
                )
              }
              variant="contained"
              color="secondary"
              className="w-full sm:w-auto"
            >
              Add Customer
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-8">
            <UsersCountCard
              title="Total Customers"
              count={customerData?.total || 0}
              icon={<CiUser size={24} />}
              color="from-blue-500 to-indigo-600"
              average={5}
              accentColor="bg-blue-100"
            />
            <UsersCountCard
              title="Active"
              count={customerData?.active || 0}
              icon={<FaUserCheck size={24} />}
              color="from-emerald-500 to-green-600"
              average={2.5}
              accentColor="bg-emerald-100"
            />
            <UsersCountCard
              title="Inactive"
              count={customerData?.inactive || 0}
              icon={<FaUserMinus size={24} />}
              color="from-rose-500 to-red-600"
              average={-2.1}
              accentColor="bg-rose-100"
            />
          </div>
        </div>
        {/* All Customers Table */}
        <div>
          <MaterialReactTable table={table} />
        </div>
      </div>
    </ProtectedLayout>
  );
}

export default UsersPage;
