// import React, { useContext, useEffect } from "react";
import SignUpForm from "../../components/forms/SignUpForm";
// import { AuthContext } from "../../context/AuthContext";
// import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
  // const { isLogin } = useContext(AuthContext);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isLogin) {
  //     navigate("/", {
  //       replace: true,
  //     });
  //   }
  // }, [isLogin, navigate]);
  return (
    <>
      <div>
        <SignUpForm />
      </div>
    </>
  );
};

export default SignUpPage;
