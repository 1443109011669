import axios from "axios";
import { getCookie } from "./cookies";

export const baseURL = "https://mlm.up.railway.app";

export const siteBaseURL = "https://www.msinternational.org.in";

let apiClient = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 30000,
  headers: {
    authorization: `Bearer ${getCookie("access_token")}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const setAuthHeader = (tokenName) => {
  apiClient = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      authorization: `Bearer ${getCookie(tokenName)}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

let formDataApiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getCookie("access_token")}`,
  },
  timeout: 60000,
});

const setFormDataAuthHeader = () => {
  formDataApiClient = axios.create({
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    timeout: 60000,
  });
};

// ------------------ Users -----------------------

const login = (payload) => {
  return apiClient.post("/users/login", payload);
};
const personalDetailUpdate = (id, payload) => {
  return formDataApiClient.patch(`/user/update/${id}`, payload);
};
const addBankDetails = (payload) => {
  return apiClient.post(`/bank-details/create`, payload);
};
const addAddressDetails = (payload) => {
  return apiClient.post(`/bank-details/create`, payload);
};
const sponsorDetails = (sponsor_id) => {
  return apiClient.get(`/users/one/${sponsor_id}`);
};

const sponsorLeftRightPositionCheck = (sponsor_id) => {
  return apiClient.get(`/mlm-structure/${sponsor_id}`);
};

const signUp = (payload) => {
  return formDataApiClient.post("/users/signup", payload);
};
const getAllUsers = (role) => {
  return apiClient.get(`/users/all/by-distributor?role=${role}`);
};
const getAllUsersByAdmin = () => {
  return apiClient.get(`/users/all`);
};
const getAUser = (id) => {
  return apiClient.get(`/users/one/${id}`);
};
const makeDistributer = (payload) => {
  return apiClient.post("/mlm-structure/create/", payload);
};
const resetPassword = (token, payload) => {
  return apiClient.patch(`/users/reset_password/${token}`, payload);
};
const forgotPassword = (payload) => {
  return apiClient.post("/users/forgot_password", payload);
};

const renewAccessToken = () => {
  return apiClient.get("/users/renew_token");
};

const getMyProfile = () => {
  return apiClient.get("/users/profiles/my");
};
const getMlmTree = (limit = 50, user_id) => {
  return apiClient.get(`/mlm-structure/all?limit=${limit}&user_id=${user_id}`);
};

const logOut = () => {
  localStorage.removeItem("user_role");
  return apiClient.delete("/users/logout");
};

// ------------------ Product -----------------------

const createProduct = (payload) => {
  return formDataApiClient.post("/product/create", payload);
};

const getAllProducts = () => {
  return apiClient.get(`/products/all`);
};
const getAllPublicProducts = () => {
  return apiClient.get(`/products/public/all`);
};

const getPublicProductById = (id) => {
  return apiClient.get(`/products/public/${id}`);
};
const getProductById = (id) => {
  return apiClient.get(`/products/${id}`);
};
const deleteProduct = (id) => {
  return apiClient.delete(`/products/delete/${id}`);
};

const updateProduct = (id, payload) => {
  return formDataApiClient.patch(`/products/${id}`, payload);
};
const addImageToProduct = (id, payload) => {
  return formDataApiClient.patch(`/products/images/${id}`, payload);
};

// ------------------ Contacts -----------------------
const CreateContact = (payload) => {
  return apiClient.post("/contact-us/create", payload);
};

const getAllContacts = () => {
  return apiClient.get("/contact-us/all");
};

const deleteContact = (id) => {
  return apiClient.delete(`/contact-us/delete/${id}`);
};

// ------------------ Orders -----------------------
const addOrder = (payload) => {
  return formDataApiClient.post(`/order/create`, payload);
};
const getAllOrders = (id) => {
  return apiClient.get(`/order/all/${id}`);
};

const getOneOrder = (id) => {
  return apiClient.get(`/order/${id}`);
};

export {
  setAuthHeader,
  setFormDataAuthHeader,
  renewAccessToken,
  forgotPassword,
  resetPassword,
  sponsorDetails,
  signUp,
  getAllUsersByAdmin,
  login,
  addOrder,
  personalDetailUpdate,
  addBankDetails,
  addAddressDetails,
  logOut,
  getAllUsers,
  getAUser,
  getAllPublicProducts,
  makeDistributer,
  getMlmTree,
  sponsorLeftRightPositionCheck,
  getMyProfile,
  getPublicProductById,
  getAllOrders,
  createProduct,
  deleteProduct,
  getOneOrder,
  getAllProducts,
  getProductById,
  updateProduct,
  addImageToProduct,
  CreateContact,
  getAllContacts,
  deleteContact,
};
