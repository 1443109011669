import { useState, useEffect, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ProtectedLayout from "../../components/layouts/ProtectedLayout";
import DashboardCard from "../../components/card/DashboardCard";
import * as api from "../../utils/apiClient";
import Loading from "../../components/Loading";
import { AuthContext } from "../../context/AuthContext";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import ProfilePhotoUpdateFormPopup from "../../components/pop/ProfilePhotoUpdateFormPopup";

export default function Dashboard() {
  const [chartData, setChartData] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalDistributer, setTotalDistributer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useContext(AuthContext);
  const [isProfilePhotoUpdate, setIsProfilePhotoUpdate] = useState(false);

  useEffect(() => {
    // Simulating data fetch
    setChartData([
      { name: "Jan", users: 4 },
      { name: "Feb", users: 3 },
      { name: "Mar", users: 2 },
      { name: "Apr", users: 2 },
      { name: "May", users: 1 },
      { name: "Jun", users: 2 },
      { name: "Jul", users: 3 },
      { name: "Aug", users: 4 },
      { name: "Sep", users: 3 },
      { name: "Oct", users: 2 },
      { name: "Nov", users: 3 },
      { name: "Dec", users: 4 },
    ]);
  }, []);

  // All Users and Distributer
  useEffect(() => {
    const getAllUsers = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllUsers("customer");
        if (data) {
          setTotalUsers(data.users);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    const getAllUsersForAdmin = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllUsersByAdmin();
        if (data) {
          setTotalUsers(data.users);
          const array = [];
          data.users.forEach((element) => {
            if (element?.role === "distributer") {
              array.push(element);
            }
          });
          setTotalDistributer(array);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    const getAllDistributer = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.getAllUsers("distributer");
        setTotalDistributer(data.distributer);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    if (localStorage.getItem("user_role") === "admin") {
      getAllUsersForAdmin();
    } else {
      getAllUsers();
      getAllDistributer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProtectedLayout>
      {isLoading && <Loading />}
      {isProfilePhotoUpdate && (
        <ProfilePhotoUpdateFormPopup
          isOpen={isProfilePhotoUpdate}
          handleClose={() => setIsProfilePhotoUpdate(false)}
        />
      )}
      <div className="max-w-[1440px] container mx-auto">
        <main>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <DashboardCard
              color={"bg-purple-500"}
              title={"Total Users"}
              data={"81% Referred by Admin"}
              total={totalUsers.length || 0}
            />
            <DashboardCard
              color={"bg-blue-500"}
              title={"Total Distributors"}
              data={"55% Referred by Admin"}
              total={totalDistributer.length || 0}
            />
            <DashboardCard
              color={"bg-red-500"}
              title={"Your Income"}
              data={"99.9% Payout done"}
              total={"₹00,000.00"}
            />
            <div className={`bg-neutral-4 text-white p-6 rounded-lg`}>
              <p className="text-xl text-center pb-2">Refer a Friend</p>
              <div className="flex gap-5 text-3xl justify-center items-center">
                {/* Clipboard */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  viewBox="0 0 441 512.398"
                  width={40}
                  height={40}
                  className="cursor-pointer"
                >
                  <path
                    fill="#262626"
                    fill-rule="nonzero"
                    d="M60.863 0h174.809c3.382 0 6.384 1.619 8.279 4.124l110.107 119.119a10.292 10.292 0 012.745 7.012h.053v119.817a149.591 149.591 0 00-20.752-3.111v-92.212h-43.666v-.042h-.161c-22.046-.349-39.33-6.222-51.694-16.784-12.849-10.979-20.063-26.614-21.504-46.039a10.145 10.145 0 01-.095-1.404V20.752H60.863c-11.02 0-21.049 4.518-28.321 11.79-7.272 7.272-11.79 17.301-11.79 28.321v338.276c0 11.015 4.521 21.037 11.796 28.311 7.278 7.28 17.31 11.802 28.315 11.802h120.749a148.132 148.132 0 008.116 20.752H60.863c-16.73 0-31.958-6.85-42.987-17.881C6.852 431.099 0 415.882 0 399.139V60.863C0 44.114 6.846 28.894 17.87 17.87 28.894 6.846 44.114 0 60.863 0zm41.915 209.516c-5.727 0-10.372-4.645-10.372-10.372 0-5.726 4.645-10.372 10.372-10.372h151.288c5.727 0 10.372 4.646 10.372 10.372 0 5.727-4.645 10.372-10.372 10.372H102.778zm0 72.682c-5.727 0-10.372-4.646-10.372-10.373 0-5.727 4.645-10.372 10.372-10.372H246.05c2.83 0 5.395 1.134 7.265 2.971a149.435 149.435 0 00-25.876 17.774H102.778zm0 72.688c-5.727 0-10.372-4.645-10.372-10.372s4.645-10.372 10.372-10.372h85.568a148.095 148.095 0 00-7.597 20.744h-77.971zM239.736 29.983v60.433c1.021 13.737 5.819 24.535 14.302 31.783 8.667 7.404 21.488 11.544 38.4 11.835v-.037h43.442L239.736 29.983z"
                  />
                  <path
                    fill="red"
                    d="M324.263 278.924c32.231 0 61.418 13.068 82.544 34.194C427.932 334.242 441 363.429 441 395.66c0 32.235-13.068 61.419-34.193 82.544-21.126 21.126-50.31 34.194-82.544 34.194-32.232 0-61.419-13.068-82.543-34.194-21.126-21.125-34.193-50.313-34.193-82.544 0-32.233 13.067-61.417 34.193-82.542 21.126-21.126 50.309-34.194 82.543-34.194z"
                  />
                  <path
                    fill="#fff"
                    fill-rule="nonzero"
                    d="M259.314 385.259l53.294 51.131v-25.501c31.087-6.242 56.245-1.706 76.605 23.635-2.87-44.517-31.926-72.566-76.605-74.456v-25.939l-53.294 51.13z"
                  />
                </svg>
                {/* Whatsapp */}
                <svg
                  height="30px"
                  width="30px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="cursor-pointer"
                >
                  <path
                    style={{ fill: "#EDEDED" }}
                    d="M0,512l35.31-128C12.359,344.276,0,300.138,0,254.234C0,114.759,114.759,0,255.117,0
	S512,114.759,512,254.234S395.476,512,255.117,512c-44.138,0-86.51-14.124-124.469-35.31L0,512z"
                  />
                  <path
                    style={{ fill: "#55CD6C" }}
                    d="M137.71,430.786l7.945,4.414c32.662,20.303,70.621,32.662,110.345,32.662
	c115.641,0,211.862-96.221,211.862-213.628S371.641,44.138,255.117,44.138S44.138,137.71,44.138,254.234
	c0,40.607,11.476,80.331,32.662,113.876l5.297,7.945l-20.303,74.152L137.71,430.786z"
                  />
                  <path
                    style={{ fill: "#FEFEFE" }}
                    d="M187.145,135.945l-16.772-0.883c-5.297,0-10.593,1.766-14.124,5.297
	c-7.945,7.062-21.186,20.303-24.717,37.959c-6.179,26.483,3.531,58.262,26.483,90.041s67.09,82.979,144.772,105.048
	c24.717,7.062,44.138,2.648,60.028-7.062c12.359-7.945,20.303-20.303,22.952-33.545l2.648-12.359
	c0.883-3.531-0.883-7.945-4.414-9.71l-55.614-25.6c-3.531-1.766-7.945-0.883-10.593,2.648l-22.069,28.248
	c-1.766,1.766-4.414,2.648-7.062,1.766c-15.007-5.297-65.324-26.483-92.69-79.448c-0.883-2.648-0.883-5.297,0.883-7.062
	l21.186-23.834c1.766-2.648,2.648-6.179,1.766-8.828l-25.6-57.379C193.324,138.593,190.676,135.945,187.145,135.945"
                  />
                </svg>
                {/* Mail */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                  className="cursor-pointer"
                >
                  <path
                    fill="#4caf50"
                    d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"
                  ></path>
                  <path
                    fill="#1e88e5"
                    d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"
                  ></path>
                  <polygon
                    fill="#e53935"
                    points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"
                  ></polygon>
                  <path
                    fill="#c62828"
                    d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"
                  ></path>
                  <path
                    fill="#fbc02d"
                    d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"
                  ></path>
                </svg>
              </div>
              {/* <small>
                Your time is limited, don't waste it living someone else's life.
              </small> */}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-8">
            <div className="w-full sm:w-1/3 bg-white p-4 rounded-lg shadow">
              <div className="text-center flex flex-col gap-2">
                {profile?.profile_pic ? (
                  <img
                    src={profile?.profile_pic}
                    alt="profile"
                    className="w-24 sm:w-28 h-24 sm:h-28 mx-auto rounded-full border-2 border-neutral-3 cursor-pointer"
                  />
                ) : (
                  <img
                    src={require("../../assets/profile.png")}
                    alt="profile"
                    className="w-24 sm:w-28 h-24 sm:h-28 mx-auto rounded-full border-2 border-neutral-3 cursor-pointer"
                  />
                )}
                <button
                  onClick={() => setIsProfilePhotoUpdate(true)}
                  className="font-semibold text-secondary"
                >
                  Upload Profile Picture
                </button>

                <h3 className="text-xl font-semibold">{profile?.user_name}</h3>
                <p className="text-sm text-gray-600">{`MS-${profile?.id}`}</p>
                <ul>
                  <li>
                    <span className="font-bold sm:font-semibold">
                      Sponsor ID :{" "}
                    </span>
                    {`MS-${profile?.distributer_id}`}
                  </li>
                  <li>
                    <span className="font-bold sm:font-semibold">
                      joining Date :{" "}
                    </span>
                    {profile?.created_at
                      ? formatDateTimeWithAMAndPM(profile?.created_at)
                      : "01/01/2024 12:00 AM"}
                  </li>
                  <li>
                    <span className="font-bold sm:font-semibold">
                      Activation Date :
                    </span>
                    <span>{profile?.joining_date}</span>
                  </li>
                  <li>
                    <span className="font-bold sm:font-semibold">
                      My Rank :
                    </span>
                    <span>{profile?.joining_date}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full bg-white p-6 rounded-lg shadow">
              <div className="text-center flex flex-col gap-5">
                <div className="block gap-5 md:flex">
                  <div className="w-full pb-5">
                    <DashboardCard
                      color={"bg-blue-500"}
                      title={"Total Left BV"}
                      data={"left business volume"}
                      total={"0"}
                    />
                  </div>
                  <div className="w-full">
                    <DashboardCard
                      color={"bg-purple-500"}
                      title={"Total Right BV"}
                      data={"right business volume"}
                      total={"0"}
                    />
                  </div>
                </div>
                <div className="w-full">
                  {/* Chart */}
                  <h3 className="text-xl font-semibold mb-2">
                    Users Joined over the time
                  </h3>
                  <div className="flex justify-between mb-4 text-sm text-gray-600">
                    <div>Weekly Joining: 0</div>
                    <div>Monthly Joining: 0</div>
                    <div>Yearly Joining: 609</div>
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={chartData}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Line type="monotone" dataKey="users" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </ProtectedLayout>
  );
}
