import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as api from "../../utils/apiClient";
import { setCookie } from "../../utils/cookies";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import TopHeroSection from "../../components/sections/TopHeroSection";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setIsLogin, setProfile, profile } = useContext(AuthContext);
  const validationSchema = Yup.object().shape({
    id: Yup.string().required("An User Id is required"),
    password: Yup.string()
      .required("Password required")
      .min(6, "Password must be at least 6 characters long"),
  });
  const initialValues = {
    id: "",
    password: "",
  };

  const handleForm = async (value) => {
    const payload = {
      id: value.id.split("MS-")[1]
        ? value.id.split("MS-")[1] * 1
        : value.id * 1,
      password: value.password,
    };
    setIsLoading(true);
    try {
      const { data } = await api.login(payload);
      localStorage.setItem("user_role", data?.profile?.role);
      if (data?.profile?.role !== "customer") {
        const accesTokenExpiresAt = Date.parse(data.access_token_expires_at);
        const refreshTokenExpiresAt = Date.parse(data.refresh_token_expires_at);
        const currentMilies = Date.now();

        const access_token = data.access_token;
        const refresh_token = data.refresh_token;
        setCookie(
          "access_token",
          access_token,
          parseInt((accesTokenExpiresAt - currentMilies) / 1000)
        );
        setCookie(
          "refresh_token",
          refresh_token,
          parseInt((refreshTokenExpiresAt - currentMilies) / 1000)
        );
        api.setAuthHeader("access_token");
        api.setFormDataAuthHeader("access_token");
        setIsLogin(true);
        setIsLoading(false);
        toast.success("Login Success");
        if (data?.profile) {
          setProfile(data?.profile);
        }
        navigate("/dashboard", { replace: true });
      } else {
        toast.error("You are unauthorized, contact your sponsor");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    handleForm(values);
    setSubmitting(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {isLoading && <Loading />}
      <TopHeroSection title="Welcome Back" />
      <div className="flex justify-center bg-neutral-2">
        {/* Old Login form */}
        {/* <div className="mx-5 border-2 border-neutral-3 bg-white p-8 rounded w-full sm:w-1/2 lg:w-1/4 text-center">
          <img
            src="/logo.webp"
            alt="logo"
            className="h-24 w-24 m-auto rounded-full"
          />
          <h2 className="text-2xl font-medium">Login to your account</h2>
          <p className="text-sm text-neutral-4 mb-3">
            Don't have an account yet?
            <span className="ml-1.5">
              <Link
                href="/signup?sponsor_id=1"
                className="hover:text-primary font-medium duration-300"
                underline="hover"
              >
                SignUp
              </Link>
            </span>
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, values, isValid }) => (
              <Form>
                <Field
                  as={TextField}
                  name="email"
                  label="Email*"
                  fullWidth
                  size={"small"}
                  margin="normal"
                  variant="outlined"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <Field
                  as={TextField}
                  name="password"
                  label="Password*"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  size={"small"}
                  margin="normal"
                  variant="outlined"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="flex justify-end mb-4">
                  <Link
                    href="/forgot_password"
                    className="hover:text-primary font-medium duration-300"
                    underline="hover"
                  >
                    Forgot password?
                  </Link>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="mt-4"
                  disabled={!values.email || !isValid || isSubmitting}
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </div> */}
        {/* New Login form */}
        <div className="mx-5 bg-white rounded w-full sm:w-1/2 -translate-y-28">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div className="p-8">
              <h2 className="text-3xl font-medium pb-3">Login</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, values, isValid }) => (
                  <Form>
                    <Field
                      as={TextField}
                      name="id"
                      label="User Id*"
                      fullWidth
                      size={"small"}
                      margin="normal"
                      variant="outlined"
                      error={touched.id && Boolean(errors.id)}
                      helperText={touched.id && errors.id}
                    />
                    <Field
                      as={TextField}
                      name="password"
                      label="Password*"
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      size={"small"}
                      margin="normal"
                      variant="outlined"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="flex justify-end mb-4">
                      <Link
                        href="/forgot_password"
                        className="hover:text-primary font-medium duration-300"
                        underline="hover"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className="mt-4"
                      disabled={!values.id || !isValid || isSubmitting}
                    >
                      Login
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="bg-card-gradient bg-opacity-60 p-8">
              <h2 className="text-3xl font-medium text-start uppercase text-black pb-3">
                Register
              </h2>
              <div className="flex flex-col gap-3">
                <Link
                  href={`/signup?sponsor_id=MS-${
                    Object.keys(profile).length > 0 ? profile?.id : "01"
                  }`}
                  underline="none"
                  className="flex items-center gap-1.5"
                >
                  <img
                    src={require("../../assets/customer.png")}
                    className="w-12 h-12"
                    alt="Distributor"
                  />
                  <span className="text-xl text-black hover:text-primary font-medium duration-300">
                    Register as Mahila Kalyan
                  </span>
                </Link>
                <Link
                  href={`/signup?sponsor_id=MS-${
                    Object.keys(profile).length > 0 ? profile?.id : "01"
                  }&fill_up_form_by=true`}
                  underline="none"
                  className="flex items-center gap-1.5"
                >
                  <img
                    src={require("../../assets/distributor.png")}
                    className="w-12 h-12"
                    alt="Distributor"
                  />
                  <span className="text-xl text-black hover:text-primary font-medium duration-300">
                    Register as Distributor
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
