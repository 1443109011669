import * as React from "react";
import Button from "@mui/material/Button";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function ShortDetailsPage() {
  return (
    <div className="block md:flex items-center gap-10 px-5 md:px-0 py-5">
      <div className="w-full md:w-[40%]">
        <img
          src="/about.png"
          alt="read_more"
          className="w-full rounded-r-full"
        />
      </div>
      <div className="w-full md:w-[60%] flex flex-col gap-3 text-start">
        <h3 className="text-3xl font-medium">MS International</h3>
        <p>
          MS International, established as a pioneering force in the direct
          selling industry, has been revolutionizing the wellness product market
          since its inception. Our company will consistently demonstrate
          remarkable growth, a testament to the superior quality of our
          products, the effectiveness of our marketing strategies, and the
          strength of our management team in delivering a rewarding and
          sustainable business model.
        </p>
        <p>
          At the heart of our success is our commitment to innovation. MS
          International continually expands its product range, introducing
          cutting-edge wellness solutions annually. Our state-of-the-art
          manufacturing partners, ensure that every product meets the highest
          standards of quality and safety.
        </p>
        <p>
          Join MS International today and be part of a dynamic organization
          that's shaping the future of wellness and direct selling. Together,
          we're not just building a business; we're creating a healthier, more
          prosperous world.
        </p>
        <Link to="/about">
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<FaArrowRight />}
          >
            Read More
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ShortDetailsPage;
